//AgGrid Imports
import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Renderers

import { DropdownFilterRenderer } from './renderers/dropdown-filter/dropdown-filter.component';
import { DropdownListRenderer } from './renderers/dropdown-list/dropdown-list.component';
import { ImportDatasetRenderer } from './renderers/import-dataset/import-dataset.component';
import { DeleteButtonRenderer } from './renderers/delete-button/delete-button.component';
import { ActionButtonsPanelRenderer } from './renderers/action-buttons-panel/action-buttons-panel.component';
import { ListboxRenderer } from './renderers/listbox/listbox.component';
import { InputSearchRenderer } from './renderers/input-search/input-search.component';
import { NumericEditorRenderer } from './renderers/numeric-editor/numeric-editor.component';

//Components
import { LoadingComponent } from './components/loading/loading.component';
import { GridComponent } from './components/grid/grid.component';
import { ButtonComponent } from './components/button/button.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputTextareaComponent } from './components/input-textarea/input-textarea.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { SelectedProjectComponent } from './components/selected-project/selected-project.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { PanelMenuComponent } from './components/panel-menu/panel-menu.component';
import { ProjectStatusComponent } from './components/project-status/project-status.component';
import { BlockUIComponent } from './components/block-ui/block-ui.component';
import { ModelCheckboxComponent } from './components/model-checkbox/model-checkbox.component';
import { EndpointDatasetComponent } from './components/endpoint-dataset/endpoint-dataset.component';


//Utils and Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
import { DropdownFilterPipe } from './pipes/dropdown-filter.pipe';
import { PrimengModule } from './utils/primeng.module';
import { ListboxComponent } from './components/listbox/listbox.component';
import { ButtonExpandGridRenderer } from './renderers/button-expand-grid/button-expand-grid.component';
import { InputNumberRenderer } from './renderers/input-number/input-number.component';
import { ViewButtonRenderer } from './renderers/view-button/view-button.component';
import { CalendarRenderer } from './renderers/calendar/calendar.component';
import { AutoCompleteRenderer } from './renderers/auto-complete/auto-complete.component';
import { QcIconComponent } from './renderers/qc-icon/qc-icon.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { StatusPanelRenderComponent } from './renderers/status-panel-render/status-panel-render.component';
import { InputTextRenderer } from './renderers/input-text/input-text.component'
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { StatusFlagRenderer } from './renderers/status-flag/status-flag.component';
import { AdvancedFiltersDialogComponent } from './components/advanced-filters-dialog/advanced-filters-dialog.component';
import { UseEndpointDatasetComponent } from './components/use-endpoint-dataset/use-endpoint-dataset.component';
import { EraEcotoxImportPopupComponent } from '../environmental-risk-assessment/era-endpoints/era-ecotox-import-popup/era-ecotox-import-popup.component';
import { LinkButtonRenderer } from './renderers/link-button/link-button.component';
import { DatasetReferenceIconRenderer } from './renderers/dataset-reference-icon/dataset-reference-icon.component';
import { InfoGridHeaderComponentComponent } from './components/info-grid-header-component/info-grid-header-component.component';


@NgModule({
  declarations: [
    //Components
    LoadingComponent,
    GridComponent,
    ButtonComponent,
    DropdownComponent,
    InputTextareaComponent,
    InputTextComponent,
    WizardComponent,
    TabMenuComponent,
    SelectedProjectComponent,
    AvatarComponent,
    PanelMenuComponent,
    OrderByPipe,
    ProjectStatusComponent,
    ListboxComponent,
    BlockUIComponent,
    ModelCheckboxComponent,
    InputSearchRenderer,
    EndpointDatasetComponent,
    AutoCompleteComponent,

    //Renderers
    ButtonExpandGridRenderer,
    DropdownFilterRenderer,
    DropdownListRenderer,
    ImportDatasetRenderer,
    DeleteButtonRenderer,
    ActionButtonsPanelRenderer,
    InputSearchRenderer,
    NumericEditorRenderer,
    ListboxRenderer,
    ViewButtonRenderer,
    CalendarRenderer,
    InputTextRenderer,
    //Pipes
    DropdownFilterPipe,
    InputNumberRenderer,
    AutoCompleteRenderer,
    QcIconComponent,
    CheckboxComponent,
    StatusPanelRenderComponent,
    StatusFlagRenderer,
    AdvancedFiltersDialogComponent,
    UseEndpointDatasetComponent,
    EraEcotoxImportPopupComponent,
    LinkButtonRenderer,
    DatasetReferenceIconRenderer,
    InfoGridHeaderComponentComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    PrimengModule,
    ReactiveFormsModule
  ],
  exports: [
    BlockUIComponent,
    LoadingComponent,
    GridComponent,
    ButtonComponent,
    DropdownComponent,
    InputTextareaComponent,
    InputTextComponent,
    WizardComponent,
    TabMenuComponent,
    SelectedProjectComponent,
    PanelMenuComponent,
    OrderByPipe,
    ProjectStatusComponent,
    ListboxComponent,
    PrimengModule,
    ModelCheckboxComponent,
    EndpointDatasetComponent,
    InputTextRenderer,
    AutoCompleteComponent,
    EraEcotoxImportPopupComponent,
    ActionButtonsPanelRenderer
  ]
})
export class SharedModule { }
