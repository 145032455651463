import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})
export class HhraRunRiskAssessmentOpexResultsColDef {

    public getColumnsDefinition(): (ColDef | ColGroupDef)[] | null {
        return [
            {
                headerName: 'Active Ingredient',
                field: 'substanceName',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
                pinned: "left",
            },
            {
                headerName: 'Calculator Group',
                field: 'calculatorGroup',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
                pinned: "left",
            },
            {
                headerName: 'Endpoints Dataset',
                field: 'description',
                width: 200,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Application Scheme',
                field: 'humanHealthApplicationScheme.name',
                width: 200,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
                pinned: "left",
            },
            {
                headerName: 'Product name',
                field: 'activeIngredient',
                width: 150,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Formulation type',
                field: 'formulationType',
                width: 200,
                filter: 'agSetColumnFilter',
                resizable: true,
                sortable: true,
                editable: false,
            },
            {
                headerName: 'OPEX Formulation type',
                field: 'OPEXFormulationType',
                width: 300,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Crop type',
                field: 'humanHealthApplicationScheme.cropType',
                width: 150,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Activity',
                field: 'humanHealthApplicationScheme.activity',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Indoor or Outdoor application',
                field: 'humanHealthApplicationScheme.indoorOutdoorApplication',
                width: 150,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Buffer strip (m)',
                field: 'humanHealthApplicationScheme.bufferStrip',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Drift reduction (%)',
                field: 'humanHealthApplicationScheme.driftReduction',
                width: 130,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Number of \n applications',
                field: 'humanHealthApplicationScheme.numberOfApplications',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Application \n interval (d)',
                field: 'humanHealthApplicationScheme.applicationInterval',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Application rate \n (L or Kg a.s./ha)',
                field: 'applicationRate',
                filter: 'agSetColumnFilter',
                width: 129,
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'PPE Scheme',
                field: 'personalProtectionEquipmentScheme.name',
                width: 190,
                filter: 'agSetColumnFilter',
                pinned: "left",
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Dermal absorption \n of product (%)',
                field: 'dermalAbsorptionOfProduct',
                width: 145,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Dermal absorption of \n in-use dilution (%)',
                field: 'dermalAbsorptionOfInUseDilution',
                width: 160,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'AOEL (mg/kg bw/day)',
                field: 'acceptableOperatorExposureLevel',
                width: 115,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'AAOEL (mg/kg bw/day)',
                field: 'acuteAcceptableOperatorExposureLevel',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Dislodgeable Foliar Residue \n (µg/cm² of foliage/kg a.s. applied/ha)',
                field: 'initialDislodgeableFoliarResidue',
                width: 235,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Foliar \n DT50 (d)',
                field: 'foliarDT50',
                width: 95,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Oral \n absorption (%)',
                field: 'oralAbsorptionOfActiveSubstance',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Inhalation \n absorption (%)',
                field: 'inhalationAbsorptionofActiveSubstance',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Sub-population',
                field: 'subPopulation',
                width: 350,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Exposure type',
                field: 'exposureType',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Exposure \n (mg/kg bw/d)',
                field: 'exposure',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: '%(A)AOEL',
                field: 'aoel',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Safety \n re-entry',
                field: 'safetyReEntry',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Hazard Index \n for combined',
                field: 'hazardIndexOfCombined',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Safety re-entry \n (d) for combined',
                field: 'safetyReEntryForCombined',
                width: 135,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Result',
                field: 'result',
                editable: false,
                resizable: true,
                sortable: true,
                filter: 'agSetColumnFilter',
                cellStyle: (params: any) => {
                    if (params.value?.toLowerCase() === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed.toLocaleLowerCase()) {
                        return { color: 'green' };
                    }
                    return { color: 'red' };
                }
            }
        ];
    }
}
