import { DropdownListRenderer } from "src/app/shared/renderers/dropdown-list/dropdown-list.component";
import { Injectable } from "@angular/core";
import { InputNumberRenderer } from "src/app/shared/renderers/input-number/input-number.component";
import { InputTextRenderer } from "src/app/shared/renderers/input-text/input-text.component";
import { ViewButtonRenderer } from "src/app/shared/renderers/view-button/view-button.component";
import { ListboxRenderer } from "src/app/shared/renderers/listbox/listbox.component";
import { Constants } from "src/app/shared/utils/constants";
import { EEAGapLogicService } from "../eea-gap.logic.service";

@Injectable({
    providedIn: 'root'
})
export class EEAGapPWCSurfaceWaterColdef {

    constructor(private eEAGapLogicService: EEAGapLogicService) { }

    public getPWCColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: 'Application Scheme',
                headerComponent: 'infoGridHeaderComponentComponent',
                headerComponentParams: {
                    name: 'Application Scheme',
                    infoText: Constants.MESSAGES.APP_SCHEME_INFO,
                    popupWidth: 450
                },
                field: 'name',
                type: 'default',
                resizable: true,
                pinned: "left",
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                    isRequired: true
                },
                editable: false,
                cellEditorParams: {
                    maxLength: 200,
                },
            },
            {
                headerName: 'Scenarios',
                type: 'default',
                width: 100,
                editable: false,
                resizable: false,
                cellRenderer: ViewButtonRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        label: 'Select',
                        disabled: !params.data.name
                    };
                }
            },
            {
                headerName: 'Simulation Type',
                field: 'simulationTypes',
                minWidth: 320,
                type: 'select',
                editable: false,
                cellRenderer: ListboxRenderer,
                cellRendererParams: {
                    options: options.simulationTypes,
                    optionValue: 'typeName',
                    optionLabel: 'typeName',
                    checkbox: true,
                    disabled: false,
                    editable: isProjectOwnershipValid,
                    multiple: true,
                },
                filter: 'agSetColumnFilter',
            },
            {
                headerName: 'Use spraydrift buffers for TPEZ',
                editable: false,
                field: 'useSpraydriftBuffersForTpez',
                type: 'selected',
                resizable: true,
                width: 130,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'useSpraydriftBuffersForTpez',
                        disabled: !isProjectOwnershipValid || !this.eEAGapLogicService.disableSprayDriftBufferForTPEZ(params.data.simulationTypes),
                    };
                }
            },
            {
                headerName: 'Date Type',
                field: 'dateType',
                resizable: true,
                width: 140,
                wrapText: true,
                type: 'default',
                filter: 'agSetColumnFilter',
                editable: false,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: Constants.DATE_TYPES,
                        columnThatModifies: 'dateType',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Crop Events',
                field: 'cropEvent',
                type: 'select',
                width: 140,
                resizable: true,
                hide: false,
                filter: 'agSetColumnfilter',
                editable: false,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: options.cropEvents,
                        optionValue: 'id',
                        optionLabel: 'name',
                        columnThatModifies: 'cropEvent',
                        disabled: !isProjectOwnershipValid
                    };
                }
            },
            {
                headerName: 'Specify Years',
                editable: false,
                field: 'specifyYear',
                type: 'selected',
                resizable: true,
                width: 90,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'specifyYear',
                        disabled: !isProjectOwnershipValid
                    };
                }
            },
            {
                headerName: 'Application',
                editable: isProjectOwnershipValid,
                cellRenderer: 'agGroupCellRenderer',
                resizable: true,
                type: 'default',
            },
            {
                headerName: 'Use an application window to account for application day uncertainty',
                editable: false,
                field: 'useApplicationWindow',
                type: 'selected',
                resizable: true,
                width: 240,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'useApplicationWindow',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Windows (days) to Windows (days)',
                wrapHeaderText: true,
                width: 140,
                field: 'windowsDaysToWindowsDays',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Steps (days) to Step(days)',
                width: 130,
                wrapHeaderText: true,
                field: 'stepDaysToStepDays',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
            },
            {
                headerName: 'Adjust application dates if raining',
                editable: false,
                field: 'adjustApplicationDatesIfRaining',
                type: 'selected',
                resizable: true,
                width: 140,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'adjustApplicationDatesIfRaining',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Intolerable Rain (cm)',
                width: 120,
                wrapHeaderText: true,
                field: 'intolerableRainCm',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Intolerable Rain Window (days)',
                width: 130,
                wrapHeaderText: true,
                field: 'intolerableRainWindowDays',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Optimum application window (days)',
                width: 155,
                wrapHeaderText: true,
                field: 'optimumApplicationWindowDays',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Minimum days between applications',
                width: 155,
                wrapHeaderText: true,
                field: 'minimumDaysBetweenApplications',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: 'deleteButtonRenderer',
                type: 'rightAligned',
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid
                }
            }
        ]
    }

    public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerCheckboxSelection: false,
                        checkboxSelection: true,
                        showDisabledCheckboxes: true,
                        width: 45,
                        minWidth: 45,
                        pinned: "left"
                    },
                    {
                        headerName: 'Application Number',
                        field: 'application_number',
                        type: 'select',
                        minWidth: 120,
                        maxWidth: 120,
                        wrapText: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numbersOfApplication,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'application_number',
                                disabled: !isProjectOwnershipValid
                            };
                        }
                    }
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        minWidth: 360,
                        width: 360,
                        type: 'leftAligned',
                        editable: isProjectOwnershipValid
                    }
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Date',
                        editable: isProjectOwnershipValid,
                        field: 'application_date',
                        minWidth: 160,
                        hide: false,
                        width: 160,
                        maxWidth: 140,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                        cellRenderer: 'calendarRender',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'application_date',
                                highlightDate: new Date(2001, 0, 1),
                                editable: isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: "Days Since",
                        field: "days_since",
                        editable: isProjectOwnershipValid,
                        minWidth: 120,
                        wrapHeaderText: true,
                        maxWidth: 120,
                        hide: false,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    },
                    {
                        headerName: 'Application Methods',
                        field: 'application_method',
                        type: 'select',
                        wrapHeaderText: true,
                        minWidth: 242,
                        maxWidth: 242,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.applicationMethods,
                                optionValue: 'id',
                                optionLabel: 'name',
                                columnThatModifies: 'application_method',
                                disabled: !isProjectOwnershipValid
                            };
                        }
                    },
                    {
                        headerName: "Depth (cm)",
                        field: "depth",
                        minWidth: 110,
                        wrapHeaderText: true,
                        maxWidth: 110,
                        editable: true,
                        hide: false,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "T-Band Split",
                        field: "t_band_split",
                        minWidth: 170,
                        editable: isProjectOwnershipValid,
                        hide: false,
                        width: 170,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "Application Effiency",
                        editable: true,
                        wrapHeaderText: true,
                        field: "efficiency",
                        minWidth: 170,
                        width: 170,
                        maxWidth: 150,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "Drift Fraction",
                        editable: isProjectOwnershipValid,
                        field: "drift",
                        minWidth: 170,
                        maxWidth: 150,
                        wrapHeaderText: true,
                        width: 170,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    }
                ]
            }

        ]
        /*public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] { //TODO The texboxComponet need to be check
            return [{
                headerName: '',
                children: [
                    {
    
                        headerCheckboxSelection: false,
                        checkboxSelection: true,
                        showDisabledCheckboxes: true,
                        width: 45,
                        minWidth: 45,
                        pinned: "left",
                    },
                    {
                        headerName: 'Application Number',
                        field: 'application_number',
                        type: 'select',
                        minWidth: 120,
                        maxWidth: 120,
                        wrapText: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numbersOfApplication,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'application_number',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                ]
            },
    
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        minWidth: 360,
                        width: 360,
                        type: 'leftAligned',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Date',
                        editable: isProjectOwnershipValid,
                        field: 'application_date',
                        minWidth: 160,
                        hide: false,
                        width: 160,
                        maxWidth: 140,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                        cellRenderer: 'calendarRender',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'application_date',
                                highlightDate: new Date(2001, 0, 1),
                                editable: isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: "Days Since",
                        field: "days_since",
                        editable: isProjectOwnershipValid,
                        minWidth: 120,
                        wrapHeaderText: true,
                        maxWidth: 120,
                        hide: false,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
                    },
                    {
                        headerName: 'Application Methods',
                        field: 'application_method',
                        type: 'select',
                        wrapHeaderText: true,
                        minWidth: 242,
                        maxWidth: 242,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.applicationMethods,
                                optionValue: 'id',
                                optionLabel: 'name',
                                columnThatModifies: 'application_method',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: "Depth (cm)",
                        field: "depth",
                        minWidth: 110,
                        wrapHeaderText: true,
                        maxWidth: 110,
                        editable: true,
                        hide: false,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter",
                    },
                    {
                        headerName: "T-Band Split",
                        field: "t_band_split",
                        minWidth: 170,
                        editable: isProjectOwnershipValid,
                        hide: false,
                        width: 170,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
    
                    },
                    {
                        headerName: "Application Effiency",
                        editable: true,
                        wrapHeaderText: true,
                        field: "efficiency",
                        minWidth: 170,
                        width: 170,
                        maxWidth: 150,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter"
    
                    },
                    {
                        headerName: "Drift Fraction",
                        editable: isProjectOwnershipValid,
                        field: "drift",
                        minWidth: 170,
                        maxWidth: 150,
                        wrapHeaderText: true,
                        width: 170,
                        type: 'leftAligned',
                        filter: "agSetColumnFilter",
                    }
                ]
            }
    
            ]
            /*public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] { //TODO The texboxComponet need to be check
                return [{
                    headerName: '',
                    children: [
                        {
        
                            headerCheckboxSelection: false,
                            checkboxSelection: true,
                            showDisabledCheckboxes: true,
                            width: 45,
                            resizable: true, pinned: "left",
                        },
                        {
                            headerName: 'Application Number',
                            width:100,
                            field: 'application_number',
                            type: 'select',
                            resizable: true,
                            wrapText: true,
                            filter: 'agSetColumnfilter',
                            editable: false,
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    options: options.numbersOfApplication,
                                    optionValue: 'value',
                                    optionLabel: 'value',
                                    columnThatModifies: 'application_number',
                                    disabled: !isProjectOwnershipValid,
                                };
                            }
                        },
                    ]
                },
        
                {
                    headerName: 'Rate (g/ha)',
                    children: [
                        {
                            headerName: 'Default Active Ingredient',
                            field: 'defaultAI',
                            cellRenderer: '',
                            resizable: true,
                            width: 360,
                            type: 'leftAligned',
                            wrapText:true,
                            editable: isProjectOwnershipValid,
                        },
                    ]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: 'Application Date ',
                            editable: false,
                            field: 'application_date',
                            wrapText:true,
                            resizable: true,
                            hide: false,
                            width:115,
                            type: 'leftAligned',
                            filter: 'agSetColumnFilter',
                            cellRenderer: CalendarRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    columnThatModifies: 'application_date',
                                    highlightDate: new Date(2001, 0, 1),
                                    editable: isProjectOwnershipValid,
                                };
                            }
                        },
                        {
                            headerName: "Days Since",
                            field: "days_since",
                            width:80,
                            resizable: true,
                            wrapHeaderText: true,
                            wrapText: true,
                            hide: false,
                            type: 'leftAligned',
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                            },
                            editable: false,
                            filter: "agSetColumnFilter"
                        },
                        {
                            headerName: 'Application Methods',
                            field: 'application_method',
                            type: 'select',
                            width:180,
                            wrapHeaderText: true,
                            resizable: true,
                            filter: 'agSetColumnfilter',
                            editable: false,
                            cellRenderer: 'dropdownlistRenderer',
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    options: options.applicationMethods,
                                    optionValue: 'id',
                                    optionLabel: 'name',
                                    columnThatModifies: 'application_method',
                                    disabled: !isProjectOwnershipValid,
                                };
                            }
                        },
                        {
                            headerName: "Depth (cm)",
                            field: "depth",
                            resizable: true,
                            width:90,
                            wrapHeaderText: true,
                            editable: false,
                            wrapText: true,
                            hide: false,
                            type: 'leftAligned',
                            filter: "agSetColumnFilter",
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                            },
                        },
                        {
                            headerName: "T-Band Split",
                            field: "t_band_split",
                            resizable: true, wrapText: true,
                            wrapHeaderText: true,
                            editable: false,
                            hide: false,
                            width: 90,
                            type: 'leftAligned',
                            filter: "agSetColumnFilter",
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                            },
                        },
                        {
                            headerName: "Application Effiency",
                            editable: false,
                            wrapHeaderText: true,
                            field: "efficiency",
                            wrapText: true,
                            resizable: true,
                            width: 120,
                            type: 'leftAligned',
                            filter: "agSetColumnFilter",
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                            },
        
                        },
                        {
                            headerName: "Drift Fraction",
                            editable: false,
                            field: "drift",
                            resizable: true,
                            wrapText: true,
                            wrapHeaderText: true,
                            width: 110,
                            type: 'leftAligned',
                            filter: "agSetColumnFilter",
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    columnThatModifies: 'drift',
                                    disabled: !isProjectOwnershipValid,
                                };
                            },
                        }
                    ]
                }
        
                ]*/

    }
}
