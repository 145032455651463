import { Injectable } from '@angular/core';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { DeleteButtonRenderer } from 'src/app/shared/renderers/delete-button/delete-button.component';
import { InputSearchRenderer } from 'src/app/shared/renderers/input-search/input-search.component';
import { Utils } from 'src/app/shared/utils/utils';
import { Project } from 'src/app/shared/models/project';
import { ColGroupDef } from 'ag-grid-community';
import { Constants } from 'src/app/shared/utils/constants';
import { ButtonExpandGridRenderer } from 'src/app/shared/renderers/button-expand-grid/button-expand-grid.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { ListboxRenderer } from 'src/app/shared/renderers/listbox/listbox.component';

@Injectable({
    providedIn: 'root'
})
export class EEAGapCoreColdef {

    constructor() { }

    public getColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {

        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        headerComponent:'infoGridHeaderComponentComponent',
                        headerComponentParams: {
                          name: 'Application Scheme',
                          infoText:Constants.MESSAGES.APP_SCHEME_INFO,
                          popupWidth:425
                        },    
                        field: 'name',
                        type: 'default',
                        width: 300,
                        initialWidth:300,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        pinned: "left",
                        cellRenderer: InputTextRenderer,
                        editable: false,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                            isRequired: true,
                        },
                        cellEditorParams: {
                            maxLength: 200,
                        },
                    },
                    {
                        headerName: 'Crop',
                        field: 'cropPk',
                        type: 'select',
                        width: 170,
                        initialWidth:100,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: options.cropList,
                            optionValue: 'CropListPk',
                            optionLabel: 'CropName',
                            columnThatModifies: 'cropPk',
                            disabled: !isProjectOwnershipValid,
                        },
                        resizable: true,
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        field: 'geographies',
                        type: 'select',
                        filter: 'agSetColumnFilter',
                        cellRenderer: ListboxRenderer,
                        editable: false,
                        // hide: (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA), // TODO: Luis Miranda - uncomment when the new Geographies component is implemented
                        hide: true,
                        cellRendererParams: {
                            options: options.geographiesList,
                            optionValue: 'key',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: false,
                            validateModel: false,
                            compartment: 'Core',
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 150,
                        initialWidth:100,
                    },
                    {
                        headerName: 'Number of Applications',
                        field: 'numberOfApplications',
                        type: 'select',
                        resizable: true,
                        width: 120,
                        initialWidth:100,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numerOfApplications,
                                optionValue: 'value',
                                optionLabel: 'value',
                                height: '300px',
                                disabled: !isProjectOwnershipValid
                            };
                        }
                    },

                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Variable rates or intervals?',
                        field: 'hasVariableRates',
                        hide: true, //TODO: Geremy Rivera - We need to change run projects (options.geographySelected == Constants.CROP_GEOGRAPHIES.USA) || (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        width: 115,
                        initialWidth:100,
                        resizable: true,
                        editable: false,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        autoHeight: true,
                        type: 'select',
                        suppressSizeToFit: true,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            valueFormatter: (params: any) => {
                                return params.value ? Constants.AFFIRMATIVE_VALUES.YES : !Utils.isEmptyValue(params.value) ? Constants.AFFIRMATIVE_VALUES.NO : '(Blanks)';
                            }
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: [{ value: true, key: Constants.AFFIRMATIVE_VALUES.YES }, { value: false, key: Constants.AFFIRMATIVE_VALUES.NO }],
                                columnThatModifies: 'hasVariableRates',
                                disabled: params.data.numberOfApplications == 1 ? false : true,
                                optionLabel: 'key',
                                optionValue: 'value',
                                parent: this,
                            };
                        },
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    },
                    {
                        headerName: '',
                        field: 'expandButton',
                        cellRenderer: ButtonExpandGridRenderer,
                        width: 50,
                        resizable: true,
                        suppressSizeToFit: true,
                        hide: true, //TODO: Geremy Rivera - We need to change run projects (options.geographySelected == Constants.CROP_GEOGRAPHIES.USA) || (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        type: 'default',
                        autoHeight: true,
                        editable: false,
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1 || params.data.hasVariableRates == false) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Interval',
                        field: 'applicationInterval',
                        type: 'default',
                        resizable: true,
                        wrapHeaderText:true,
                        wrapText:true,
                        width: 110,
                        initialWidth:100,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: (params:any) => {
                            return {
                                disabled: !isProjectOwnershipValid || params.data.numberOfApplications === 1
                            }
                        },
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        editable: isProjectOwnershipValid,
                        cellRenderer: '',
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        field: 'bbchEarliest',
                        type: 'select',
                        width: 100,
                        initialWidth:100,
                        resizable: true,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    }
                ]
            },
            {
                    headerName: '',
                    colId: 'delete',
                    field: 'deleteButton',
                    cellRenderer: DeleteButtonRenderer,
                    type: 'rightAligned',
                    width: 125,
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    }
                
            }
        ];
    }

    public getInverseColumnsDefinition(options: any, { useNumberOfApplications, useIntervalBetweenApplications, useRateGHA, useBBCH }: Project, isProjectOwnershipValid: boolean): ColGroupDef[] {
        const inverseNumOfApplications = {
            headerName: 'Number of Applications',
            children: [
                {
                    headerName: 'Min',
                    field: 'minNumberOfApplications',
                    type: 'select',
                    resizable: true,
                    width: 100,
                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Max',
                    field: 'maxNumberOfApplications',
                    type: 'select',
                    resizable: true,
                    width: 100,
                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Increment',
                    field: 'incrementNumberOfApplications',
                    type: 'select',
                    resizable: true,
                    width: 100,
                    editable: false,
                    filter: 'agSetColumnfilter',
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                }]
        };
        const numberOfApplications = {
            headerName: 'Number of Applications',
            children: [{
                headerName: '',
                field: 'numberOfApplications',
                type: 'select',
                resizable: true,
                width: 150,
                editable: false,
                filter: 'agSetColumnfilter',
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: options.numerOfApplications,
                        optionValue: 'value',
                        optionLabel: 'value',
                        columnThatModifies: 'numberOfApplications',
                        height: '300px',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            }]
        };

        const inverseApplicationInterval = {
            headerName: 'Application Interval',
            hide: !useIntervalBetweenApplications,
            editable: isProjectOwnershipValid,
            children: [
                {
                    headerName: 'Min',
                    field: 'minApplicationInterval',
                    type: 'default',
                    resizable: true,
                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                },
                {
                    headerName: 'Max',
                    field: 'maxApplicationInterval',
                    type: 'default',
                    resizable: true,
                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                },
                {
                    headerName: 'Increment',
                    field: 'incrementApplicationInterval',
                    type: 'default',
                    resizable: true,
                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                }],
        };
        const applicationInterval = {
            headerName: 'Application Interval',
            children: [{
                headerName: '',
                field: 'applicationInterval',
                type: 'default',
                resizable: true,
                width: 150,
                filter: 'agSetColumnfilter',
                cellRenderer: InputNumberRenderer,
                editable: isProjectOwnershipValid,
            }]
        };

        const inverseBBCH = {
            headerName: '',
            children: [{
                headerName: 'BBCH (earliest)',
                editable: isProjectOwnershipValid,
                children: [
                    {
                        headerName: 'Min',
                        field: 'minBbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Max',
                        field: 'maxBbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                                instance: this,
                                columnThatModifies: 'maxBbchEarliest',
                            };
                        }
                    },
                    {
                        headerName: 'Increment',
                        field: 'incrementBbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    }
                ]
            }]
        };
        const BBCH = {
            headerName: '',
            children: [
                {
                    headerName: 'BBCH (earliest)',
                    children: [{
                        headerName: '',
                        field: 'bbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable: true,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    }]
                }
            ]
        }

        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        editable: isProjectOwnershipValid,
                        pinned:'left',
                        children: [{
                            headerName: '',
                            field: 'name',
                            type: 'default',
                            resizable: true,
                            width: 300,
                            filter: 'agSetColumnFilter',
                            cellRenderer: InputTextRenderer,
                            cellRendererParams: {
                                maxLength: 200,
                            },
                        }],
                    },
                    {
                        headerName: 'Crop',
                        children: [{
                            headerName: '',
                            field: 'cropPk',
                            type: 'select',
                            resizable: true,
                            width: 170,
                            filter: 'agSetColumnfilter',
                            editable: true,
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                                },
                            },
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: {
                                options: options.cropList,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                disabled: !isProjectOwnershipValid,
                            }
                        }],
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        children: [{
                            headerName: '',
                            field: 'geographies',
                            type: 'select',
                            hide: (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA),
                            filter: 'agSetColumnFilter',
                            cellRenderer: 'listBoxRenderer',
                            cellRendererParams: {
                                options: options.geographiesList,
                                optionValue: 'key',
                                optionLabel: 'name',
                                checkbox: true,
                                filter: false,
                                multiple: true,
                                disabled: false,
                                editable: isProjectOwnershipValid,
                                validateModel: false,
                                compartment: 'Core'
                            },
                            wrapText: true,
                            resizable: true,
                            sortable: true,

                            width: 150,
                            editable: false,
                        }],
                    },
                    useNumberOfApplications ? inverseNumOfApplications : numberOfApplications,
                    useIntervalBetweenApplications ? inverseApplicationInterval : applicationInterval,
                ]
            }, {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAIGHA',
                        editable: isProjectOwnershipValid,
                        cellRenderer: '',
                    },
                ]
            }, {
                headerName: 'Rate (lb/acre)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAILBACRE',
                        editable: isProjectOwnershipValid,
                        cellRenderer: '',
                    },
                ]
            },
            useBBCH ? inverseBBCH : BBCH,
            {
                headerName: '',
                children: [{
                    headerName: '',
                    colId: 'action',
                    field: 'deleteButton',
                    cellRenderer: DeleteButtonRenderer,
                    type: 'rightAligned',
                    width: 125,

                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    }
                }]
            }
        ];
    }

    configureDetailGrid(isProjectOwnershipValid: boolean): ColGroupDef[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Number',
                        field: 'applicationNumber',
                        width: 110,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: false,
                        sort: "asc",
                    },
                    {
                        headerName: 'Application Interval',
                        field: 'applicationInterval',
                        width: 110,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: isProjectOwnershipValid,
                    }
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        editable: !isProjectOwnershipValid,
                    },
                ]
            },
        ]
    }
}
