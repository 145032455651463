import { Component, inject, computed, Output, EventEmitter, Input, OnInit } from '@angular/core';

import { EeaGapSurfacewaterPwcScenariosLogicService } from './eea-gap-surfacewater-pwc-scenarios.logic.service';
import { ListboxChangeEvent, ListboxClickEvent } from 'primeng/listbox';
import { CropXRegionUI, SelectedScenariosByRow } from 'src/app/shared/models/pwc/scenarios.interface';
import { ApplicationScheme } from 'src/app/shared/models/application-scheme';

@Component({
  selector: 'eea-gap-surfacewater-pwc-scenarios',
  templateUrl: './eea-gap-surfacewater-pwc-scenarios.component.html',
  styleUrls: ['./eea-gap-surfacewater-pwc-scenarios.component.css']
})
export class EeaGapSurfacewaterPwcScenariosComponent implements OnInit {

  @Input() row?: ApplicationScheme;
  @Input({required: true}) isProjectOwner! : boolean;
  @Output() onSelectScenarios = new EventEmitter<SelectedScenariosByRow>;

  public visible = computed( ()=> this.logicService.visible() );
  public listboxHeight:    number = 425;
  public crops:            string[] = [];
  public regions:          CropXRegionUI[] = [];
  public selectedCrop?:    string;
  public selectedRegions?: number[] = [];
  public selectedScenarios: CropXRegionUI[] = [];

  private logicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  public ngOnInit(): void {
    if( !this.row ) return;
    this.selectedRegions = this.row.cropXRegionPks;
    this.selectedScenarios = this.logicService.cropXRegion.filter(c => this.selectedRegions?.includes(c.crop_x_region_pk));
  }

  public onShow(): void {
    this.crops = this.logicService.crops;
  }

  public onClose(): void {
    const selectedScenariosIds: SelectedScenariosByRow = {
      rowId: this.logicService.currentRowId()!,
      selectedScenariosIds: this.selectedScenarios.map(s => s.crop_x_region_pk)
    }

    this.onSelectScenarios.emit( selectedScenariosIds );

    this.logicService.visible.set( false );
    this.logicService.currentRowId.set( undefined );
    this.selectedCrop = undefined;
    this.selectedRegions = [];
    this.selectedScenarios = [];
    this.row = undefined;
  }

  public onCropChange(event: ListboxChangeEvent): void {
    const {value: crop} = event;
    this.regions = this.logicService.getRegionsByCrop(crop);
  }

  public onClickRegion(event: ListboxClickEvent): void {
    const option: CropXRegionUI = event.option;
    if(this.selectedScenarios.some(s => s.crop_x_region_pk === option.crop_x_region_pk))
      this.selectedScenarios = this.selectedScenarios.filter(s => s.crop_x_region_pk !== option.crop_x_region_pk);
    else
      this.selectedScenarios.push(option);
    this.selectedScenarios = [...this.selectedScenarios];
  }

  public removeScenario(crop_x_region_pk: number): void {
    this.selectedScenarios = this.selectedScenarios.filter(s => s.crop_x_region_pk !== crop_x_region_pk);
    this.selectedRegions = this.selectedRegions?.filter(r => r !== crop_x_region_pk);
  }

}
