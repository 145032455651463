import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, RowGroupingDisplayType, ValueGetterParams } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { HhraRunRiskAssessmentOpexResultsColDef } from './hhra-run-risk-assessment-opex-results-coldef';
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';
import { Constants } from '../../../shared/utils/constants';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Utils } from 'src/app/shared/utils/utils';
import { HHRAConstants } from '../../hhra-constants';

@Component({
  selector: 'app-hhra-run-risk-assessment-opex-result',
  templateUrl: './hhra-run-risk-assessment-opex-result.component.html',
  styleUrls: ['./hhra-run-risk-assessment-opex-result.component.css']
})

export class HhraRunRiskAssessmentOpexResultComponent implements OnInit {

  @Input() selectedProject?: Project;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() opexInputsCombinations: OpexInputs[] = [];
  @Output() status: EventEmitter<any>;

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  columnDefs: (ColDef | ColGroupDef)[] | null = [];
  rowData: any[] = [];
  isLoading!: boolean;
  subscription!: Subscription;
  projectStatusStyle = {
    statusId: this.selectedProject?.status,
    statusName: "",
    badgeStyle: ""
  }
  
  groupDisplayType: RowGroupingDisplayType = 'custom';

  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly hhraRunRiskAssessmentOpexResultsColDef: HhraRunRiskAssessmentOpexResultsColDef,
    private readonly runRiskApiService: RunRiskApiService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService) {
    this.status = new EventEmitter<boolean>();   
  }

  ngOnInit() {
    this.rowData = [];
    this.isLoading = true;
    this.monitorProjectStatus();
  }

  getData() {
    this.columnDefs = this.hhraRunRiskAssessmentOpexResultsColDef.getColumnsDefinition();
    this.runRiskApiService.GetOPEXRunsByProject(this.selectedProject?.projectPk!).subscribe((data: OpexInputs[]) => {
      this.rowData = this.transformData(data);
      this.isLoading = false;
    });
  }

  monitorProjectStatus(): void {
    this.subscription = timer(0, 10000).pipe(
      switchMap(() => this.projectApiService.getProjectStatus(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_NUMERIC.COMPLETED) {
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.statusStyle(status, Constants.PROJECT_STATUS.COMPLETE, Constants.BADGE_STYLE.COMPLETED);
          this.getData();
          this.subscription.unsubscribe();
        }
        if(this.selectedProject!.status == Constants.PROJECT_STATUS_NUMERIC.FAILED){
          this.statusStyle(status, Constants.PROJECT_STATUS.FAILED, Constants.BADGE_STYLE.FAILED);
          this.isLoading = false;
          this.subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  statusStyle(statusId: number, status: string, bageStyle: string) {
    this.projectStatusStyle = {
      badgeStyle: bageStyle,
      statusId: statusId,
      statusName: status.concat("\u00A0")
    }
    this.status.emit(this.projectStatusStyle);
  }

  transformData(data: OpexInputs[]): any[] {
    const resultData: any[] = [];
    const combinationMap = new Map<string, boolean>();
    const combinedCombinationMap = new Map<string, boolean>();

    data?.forEach(opexInputsObject => {
        let isCombinedAaoelInValid = false;
        const combinedCombinationKey = `${opexInputsObject.substancePropertiesDataSet[0].calculatorGroup}_${opexInputsObject.personalProtectionEquipmentScheme.name}_${opexInputsObject.humanHealthApplicationScheme.name}_${opexInputsObject.subPopulation}_${opexInputsObject.exposureType}`;
        
        opexInputsObject.substancePropertiesDataSet.forEach(element => {
            const combinationKey = `${element.calculatorGroup}_${opexInputsObject.humanHealthApplicationScheme.name}_${element.substanceName}_${opexInputsObject.subPopulation}_${opexInputsObject.exposureType}`;

            const aaoel = +element.endpoints.AcuteAcceptableOperatorExposureLevel;
            if(!isCombinedAaoelInValid) isCombinedAaoelInValid = aaoel === null || aaoel === 0;
            if (this.isRowInvalid(opexInputsObject.exposureType, opexInputsObject.subPopulation, undefined, aaoel)) return;

            if (!combinationMap.has(combinationKey) || opexInputsObject.subPopulation === HHRAConstants.SUB_POPULATIONS.OPERATIONAL_ML_APPLICATION_EXPOSURE) {
                combinationMap.set(combinationKey, true);

                resultData.push(this.createResultData(opexInputsObject, element));
            }
        });

        if (this.isRowInvalid(opexInputsObject.exposureType, opexInputsObject.subPopulation, isCombinedAaoelInValid)) return;

        if (!combinedCombinationMap.has(combinedCombinationKey) && opexInputsObject.substancePropertiesDataSet.length > 1) {
            combinedCombinationMap.set(combinedCombinationKey, true);

            resultData.push(this.createCombinedResultData(opexInputsObject));
        }
    });

    return resultData;
  }

  createResultData(opexInputsObject: OpexInputs, element: any): any {
      return {
          OPEXFormulationType: opexInputsObject.OPEXFormulationType,
          activeIngredient: opexInputsObject.activeIngredient,
          applicationRate: opexInputsObject.humanHealthApplicationScheme.aeConcentration == undefined
              ? opexInputsObject.humanHealthApplicationScheme.aiConcentration
              : opexInputsObject.humanHealthApplicationScheme.aeConcentration,
          formulationType: opexInputsObject.formulationType,
          humanHealthApplicationScheme: opexInputsObject.humanHealthApplicationScheme,
          personalProtectionEquipmentScheme: opexInputsObject.personalProtectionEquipmentScheme,
          subPopulation: opexInputsObject.subPopulation,
          calculatorGroup: element.calculatorGroup,
          exposureType: opexInputsObject.exposureType,
          dermalAbsorptionOfProduct: element.endpoints.DermalAbsorptionOfProduct,
          dermalAbsorptionOfInUseDilution: element.endpoints.DermalAbsorptionOfInUseDilution,
          acceptableOperatorExposureLevel: element.endpoints.AcceptableOperatorExposureLevel,
          acuteAcceptableOperatorExposureLevel: element.endpoints.AcuteAcceptableOperatorExposureLevel,
          initialDislodgeableFoliarResidue: element.endpoints.InitialDislodgeableFoliarResidue,
          foliarDT50: element.endpoints.FoliarDT50,
          oralAbsorptionOfActiveSubstance: element.endpoints.OralAbsorptionOfActiveSubstance,
          inhalationAbsorptionofActiveSubstance: element.endpoints.InhalationAbsorptionofActiveSubstance,
          description: element.description,
          substanceName: element.substanceName,
          exposure: element.exposure,
          aoel: element.aoel,
          safetyReEntry: element.safetyReEntry,
          result: element.result,
          hazardIndexOfCombined: HHRAConstants.EMPTY,
          safetyReEntryForCombined: HHRAConstants.EMPTY,
      };
  }

  createCombinedResultData(opexInputsObject: OpexInputs): any {
      return {
          OPEXFormulationType: opexInputsObject.OPEXFormulationType,
          activeIngredient: opexInputsObject.activeIngredient,
          applicationRate: opexInputsObject.humanHealthApplicationScheme.aeConcentration == undefined
              ? opexInputsObject.humanHealthApplicationScheme.aiConcentration
              : opexInputsObject.humanHealthApplicationScheme.aeConcentration,
          formulationType: opexInputsObject.formulationType,
          humanHealthApplicationScheme: opexInputsObject.humanHealthApplicationScheme,
          personalProtectionEquipmentScheme: opexInputsObject.personalProtectionEquipmentScheme,
          subPopulation: opexInputsObject.subPopulation,
          calculatorGroup: opexInputsObject.substancePropertiesDataSet[0].calculatorGroup,
          exposureType: opexInputsObject.exposureType,
          description: opexInputsObject.substancePropertiesDataSet[0].description,
          substanceName: HHRAConstants.COMBINED,
          exposure: HHRAConstants.EMPTY,
          aoel: HHRAConstants.EMPTY,
          safetyReEntry: HHRAConstants.EMPTY,
          result: this.getCombinedResult(opexInputsObject.hazardIndexOfCombined),
          hazardIndexOfCombined: opexInputsObject.hazardIndexOfCombined,
          safetyReEntryForCombined: opexInputsObject.safetyReEntryForCombined,
      };
  }

  getCombinedResult(hazardIndexOfCombined: string | number): string {
      if (Utils.isEmptyValue(hazardIndexOfCombined)) return HHRAConstants.NA;
      if (isNaN(+hazardIndexOfCombined)) return HHRAConstants.NA;
      return +hazardIndexOfCombined <= 1 ? Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed : Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Failed;
  }

  isRowInvalid(exposureType: string, subPopulation: string, isCombinedAaoelInValid: boolean = false, aaoel?: number): boolean {
      const isAaoelInvalid = isCombinedAaoelInValid || aaoel === null || aaoel === 0;
      const isExposureTypeInvalid = exposureType === HHRAConstants.EXPOSURE_TYPES.ACUTE_SYSTEM_EXPOSURE;
      const isSubPopulationInvalid = subPopulation === HHRAConstants.SUB_POPULATIONS.BYSTANDER_ADULT || subPopulation === HHRAConstants.SUB_POPULATIONS.BYSTANDER_CHILD;
      const isSubPopulationRecreationalExposure = subPopulation === HHRAConstants.SUB_POPULATIONS.RECREATIONAL_EXPOSURE;

      return (isAaoelInvalid && (isExposureTypeInvalid || isSubPopulationInvalid)) || isSubPopulationRecreationalExposure;
  }

  onButtonExpandClick(event: any) {
    this.toggleDetailGrid(event, this.grid);
  }

  toggleDetailGrid(event: any, grid: GridComponent) {
    event.row = event.row ? event.row : event.data;
    var node1 = grid.gridApi.getRowNode(event?.node ? event?.node?.id : event.id)!;
    grid.gridApi.setRowNodeExpanded(node1, event?.row?.isExpanded);
  }
}