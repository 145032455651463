<div class="flex flex-row-reverse flex-wrap">
    <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary"
        [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
        (onClickEvent)="onDownloadClick()"></app-button>
    <app-button style="margin-right: 5px;" label="Re-Run" iconPos="right" [outlined]="true" severity="secondary"
        [icon]="isRefreshingIcon ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
        (onClickEvent)="refreshData()"></app-button>
    <div class="col-2" style="padding-top: 8px; padding-left: 94px;">
        <span>Project Status: &nbsp; <strong>{{ projectStatusStyle.statusName
                }}</strong></span>&nbsp;
        <p-badge pTooltip="{{selectedProject?.description}}" tooltipPosition="top"
            styleClass="{{projectStatusStyle.badgeStyle}}"></p-badge>
    </div>
</div>