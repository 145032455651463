import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GridTransaction } from './grid-transaction';
import { Constants } from '../../utils/constants';
import { ColDef, RowClickedEvent, DomLayoutType, ColGroupDef, ColumnApi, GridApi, IDetailCellRendererParams, GridOptions, GridReadyEvent, RowSelectedEvent, IRowNode, RowGroupOpenedEvent, RowGroupingDisplayType, GetDataPath } from 'ag-grid-community';
import Swal from 'sweetalert2';
import { Observable, Subscription, of } from 'rxjs';
import { DropdownListRenderer } from '../../renderers/dropdown-list/dropdown-list.component';
import { DeleteButtonRenderer } from '../../renderers/delete-button/delete-button.component';
import { GridLogicService } from './grid.logic.service';
import { ListboxRenderer } from '../../renderers/listbox/listbox.component';
import { ImportDatasetRenderer } from '../../renderers/import-dataset/import-dataset.component';
import { InputNumberRenderer } from '../../renderers/input-number/input-number.component';
import { AutoCompleteRenderer } from '../../renderers/auto-complete/auto-complete.component';
import { Project } from '../../models/project';
import { CheckboxComponent } from '../../renderers/checkbox/checkbox.component';
import { CalendarRenderer } from '../../renderers/calendar/calendar.component';
import { StatusPanelRenderComponent } from '../../renderers/status-panel-render/status-panel-render.component';
import { InputTextRenderer as InputTextRender } from '../../renderers/input-text/input-text.component';
import { SelectionValues } from '../../models/selectionValues';
import { StatusFlagRenderer } from '../../renderers/status-flag/status-flag.component';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { LinkButtonRenderer } from '../../renderers/link-button/link-button.component';
import { InfoGridHeaderComponentComponent } from '../info-grid-header-component/info-grid-header-component.component';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnChanges {

  @Input() selectedProject?: Project;
  @Input() columnDefs: any;
  @Input() rowData: any;
  @Input() rowHeight: number = 45;
  @Input() headerHeight: number = 30;
  @Input() rowSelection: 'single' | 'multiple' = 'single';
  @Input() suppressRowClickSelection = false;
  @Input() components: any;
  @Input() domLayout: DomLayoutType = 'autoHeight';
  @Input() style: string;
  @Input() autoAdjustColumns: boolean = false;
  @Input() isSaving!: boolean;
  @Input() compartment: string = '';;
  @Input() useAddAction!: boolean;
  @Input() useCustomNewRow: boolean = false;
  @Input() useSaveAction!: boolean;
  @Input() useModels!: boolean;
  @Input() useDeleteAction!: boolean;
  @Input() pagination: boolean = true;
  @Input() paginationPageSize: number = 12;
  @Input() autosize: boolean = false;
  @Input() detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  @Input() detailGroupDisplayType?: RowGroupingDisplayType;
  @Input() autoGroupColumnDef: any = null;
  @Input() detailCellRenderer: any = null;
  @Input() suppressCopyRowsToClipboard: boolean = true;
  @Input() height: number = 611;
  @Input() rowMultiSelectWithClick: boolean = false;
  @Input() groupDisplayType?: RowGroupingDisplayType;
  @Input() groupHideOpenParents: boolean = false;
  @Input() animateRows: boolean = false;
  @Input() detailGroupHideOpenParents: boolean = false;
  @Input() treeData: boolean = false;
  @Input() groupIncludeFooter: boolean = false;
  @Input() groupIncludeTotalFooter: boolean = false;

  @Input() getDataPath?: GetDataPath;

  @Output() DeleteDetailEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter<any[]>();
  @Output() refreshEvent = new EventEmitter<any>();
  @Output() cellValueChangedEvent = new EventEmitter<any>();
  @Output() listBoxSelectionChangeEvent = new EventEmitter<any>();
  @Output() buttonExpandParams = new EventEmitter<any>();
  @Output() dropDownSelectionChangeEvent = new EventEmitter<any>();
  @Output() selectionChangedEvent = new EventEmitter<any>();
  @Output() calendarSelectionChangeEvent = new EventEmitter<any>();
  @Output() onClickButtonEvent = new EventEmitter<any>();
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() onAutoCompleteChanged = new EventEmitter<any>();
  @Output() onInputNumberChanged = new EventEmitter<any>();
  @Output() onModelOptionsChangedEvent = new EventEmitter<string[]>();
  @Output() onFirstDataRenderedEvent = new EventEmitter<any>();
  @Output() onCopyDatasetEmit = new EventEmitter<ImportDataSet>();
  private detailGridApi!: GridApi;
  private detailColumnApi!: ColumnApi;
  @Input() columnSizeMode: string = 'autoSize';
  @Output() AddDetailEvent = new EventEmitter<any>();
  @Output() detailSelectionChangedEvent = new EventEmitter<any[]>();
  @Output() deletedData = new EventEmitter<any>();
  @Output() onBlurInputText = new EventEmitter<any>();
  @Output() onRowSelectedEmit = new EventEmitter<any>();
  @Output() onRowExpanded = new EventEmitter<RowGroupOpenedEvent>();
  @Output() onSelectedRowEvent = new EventEmitter<RowSelectedEvent>();
  @Output() onCheckboxSelectedEvent = new EventEmitter<any>();

  @Input() isMasterDetail: boolean = false;
  @Input() detailAutoRowHeigth: boolean = false;
  @Input() groupDefaultExpanded: number = 0;
  @Input() detailGroupDefaultExpanded: number = 0;
  @Input() useDetailAddButton: boolean = true;
  @Input() isProjectOwnershipValid: boolean = false;

  private columnApi!: ColumnApi;
  public statusBar: any;
  public statusBarDetailComponent: any;

  public detailCellRendererParams: any = null;
  public gridApi: any;
  public assemblingTemplate = false;
  public selectedIds: any;
  public context: any;
  gridColumnApi: any;
  public gridOptions: GridOptions = {};
  transactionList: Array<GridTransaction>;
  dataIsModified = false;
  editing = false;
  enterKeyValue = 'Enter';
  uidEvent = 'UID';
  shouldValidate = true;
  savedFilterModel: any = [];
  columnDetailsData: any;
  cloneIsVisible = true;
  saveConstant: string = 'save';
  cancelConstant: string = 'cancel';
  changeStatusConstant: string = 'changeStatus';

  columnsForValidateInExport = ['relatedToPk', 'MetStructure'];
  columnsNotIncludedExcelExport = ['MetStructure'];
  thisComponent = this;
  hasDataToSave = false;
  SaveDialogElement = Constants.SaveDialogElement;
  CancelDialogElement = Constants.CancelDialogElement;
  ChangeStatusDialogElement = Constants.ChangeStatusDialogElement;
  selectedRegistrationStatus: any;
  selectedInputtingStatus: any;

  filteringValue: string = '';
  modelsInRowData: string[] = [];
  hideBatchUpload: boolean = false;
  openFractionModal = false;
  parentInstance: any = {};
  gridId: string = 'agGrid';
  RedrawRowSubscription: Subscription | undefined;
  subscriptions: Subscription[] = [];
  public buttonCliked: boolean = false;
  public buttonInputParams: any;
  public selectedRow: any;
  noSave: boolean = false;
  deleteColumn: ColDef = {
    width: 125,
    minWidth: 125,
    editable: false,
    colId: 'action',
    cellRenderer: 'deleteButtonRenderer',
    type: 'rightAligned',
  }

  public defaultColDef: ColDef = {
    editable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  public frameworkComponents?: {
    deleteButtonRenderer: typeof DeleteButtonRenderer,
    dropdownlistRenderer: typeof DropdownListRenderer,
    listBoxRenderer: typeof ListboxRenderer,
    importDatasetRender: typeof ImportDatasetRenderer,
    inputNumberRenderer: typeof InputNumberRenderer,
    autocompleteRenderer: typeof AutoCompleteRenderer,
    checkBoxRenderer: typeof CheckboxComponent,
    calendarRender: typeof CalendarRenderer,
    inputTextRender: typeof InputTextRender,
    StatusFlagRenderer: typeof StatusFlagRenderer,
    LinkButtonRenderer: typeof LinkButtonRenderer
    infoGridHeaderComponentComponent: typeof InfoGridHeaderComponentComponent
  };

  public detailFrameworkComponents: {
    [p: string]: any;
  } = {
      deleteButtonRenderer: DeleteButtonRenderer,
      dropdownlistRenderer: DropdownListRenderer,
      listBoxRenderer: ListboxRenderer,
      importDatasetRender: ImportDatasetRenderer,
      inputNumberRenderer: InputNumberRenderer,
      checkBoxRenderer: CheckboxComponent,
      calendarRender: CalendarRenderer,
      statusFlagRenderer: StatusFlagRenderer
    };
  columnTypes = {
    default: { editable: true, resizable: true, sortable: true, hide: false },
    select: { resizable: true, sortable: true, hide: false },
  };

  constructor(private gridService: GridLogicService) {
    this.transactionList = new Array();
    this.style = '';
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: StatusPanelRenderComponent,
          key: 'statusPanel',
        },
      ],
    };
    this.gridOptions.statusBar = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' }
      ]
    };
    this.gridOptions = {
      onRowGroupOpened: this.onRowGroupOpened.bind(this)
    };

  }

  AddDetailClicked() {
    const AddRowIndex = -Math.abs(this.detailGridApi.getDisplayedRowCount() + 1);
    this.AddDetailEvent.emit({ index: AddRowIndex, parent: this.gridApi.getSelectedRows()[0] });
  }

  onColumnGroupOpened(event: any) {
    this.setColumnSize(this.columnApi, this.gridApi);
  }

  ngOnInit(): void {

    this.style = `width: auto; text-align: left; z-index:-1;font-size:10px; color: #656565; ${this.domLayout == 'normal' ? `height: ${this.height}px;` : ''}`
    this.context = this;
    this.frameworkComponents = {
      deleteButtonRenderer: DeleteButtonRenderer,
      dropdownlistRenderer: DropdownListRenderer,
      listBoxRenderer: ListboxRenderer,
      importDatasetRender: ImportDatasetRenderer,
      inputNumberRenderer: InputNumberRenderer,
      autocompleteRenderer: AutoCompleteRenderer,
      checkBoxRenderer: CheckboxComponent,
      calendarRender: CalendarRenderer,
      inputTextRender: InputTextRender,
      StatusFlagRenderer: StatusFlagRenderer,
      LinkButtonRenderer: LinkButtonRenderer,
      infoGridHeaderComponentComponent: InfoGridHeaderComponentComponent
    };
    if (!this.detailCellRendererParams) this.setDetailCellRendererParams();
    if (this.columnDefs) {
      const isDeletePresent = this.columnDefs.some((column: { cellRenderer: any; }) => column.cellRenderer == this.deleteColumn.cellRenderer);
      if (!isDeletePresent && this.useDeleteAction)
        this.columnDefs.push(this.deleteColumn);
      if (isDeletePresent && !this.useDeleteAction)
        this.columnDefs.pop();
    }

    this.gridService.transaction$.subscribe(transaction => {
      this.gridApi.applyTransaction(transaction)

    });

    this.RedrawRowSubscription = this.gridService.redrawRows$.subscribe(redraw => {
      this.gridApi.redrawRows();
    });
    this.subscriptions.push(this.RedrawRowSubscription);
  }

  onDeleteClick(event: any) {
    this.deleteEvent.emit(event);
  }

  deleteRowFromGrid(nodeID?: any): void {
    if (nodeID === undefined) {
      this.deleteRowFromMainGrid();
    } else {
      this.deleteRowFromMainByMultiSelection(nodeID);
    }
  }

  onCellClicked(event: any) {
    if (event.colDef.field == 'arrow') {
      let newValue = !event.data.isExpanded;
      event.node.setDataValue('isExpanded', newValue);
    }
  }
  onCellMouseOver(pEvent: any) {
    this.filteringValue = pEvent.colDef.field;
  }

  modelUpdated(event: any) {
    let expandedDetails: boolean = false;
    if (this.gridApi) {
      this.gridApi.clientSideRowModel.rowsToDisplay.forEach((element: any) => {
        if (element.expanded === true) {
          //   expandedDetails = true;
        }
      });
    }
  }

  onCellEditingStarted(event: any) {
    this.gridApi.deselectAll();
    this.editing = true;
  }

  onCellEditingStopped(event: any): void {
    this.editing = false;
  }

  onCellValueChanged(event: any): void {
    const primaryKeyValue = event.data[Object.keys(event.data)[0]];
    this.CreateTransaction(primaryKeyValue, event.node.id, event.oldValue, event.data);
    const changedData = [event.data]
    event.api.applyTransaction({ update: changedData });
    event.api.refreshCells();
  }

  CreateTransaction(primaryKeyValue: any, id: any, oldValue: any, data: any): void {
    let gridTransaction = this.transactionList.find((obj) => obj.getRowUniqueID() === id);
    let transactionType: number;
    if (data['newRow'] != true) {
      transactionType = GridTransaction.update;
    } else {
      primaryKeyValue = '-1';
      transactionType = GridTransaction.insert;
    }

    if (!gridTransaction) {
      gridTransaction = this.newTransaction(id, transactionType);
      data.originalValue = oldValue;
      this.transactionList.push(gridTransaction);
    }
    gridTransaction.setRow(data);
  }

  checkBoxChanged(params: any) {
    if (this.selectedRow) this.selectedRow.haveChanges = true;
    if (params.colDef.field == 'selected')
      this.selectionChangedEvent.emit(this.selectedRow);
    if (params.requireConfirmation)
      this.gridApi.redrawRows(params.node);
  }

  newTransaction(pRowUniqueID: string, pType: number): GridTransaction {
    this.dataIsModified = true;
    let gridTransaction: GridTransaction = new GridTransaction();
    gridTransaction.setTransactionType(pType);
    gridTransaction.setRowUniqueID(pRowUniqueID);
    return gridTransaction;
  }

  revertAllChanges(): void {
    this.transactionList = [];
    this.editing = false;
  }

  add(): void {
    const location = this.getLocationFromPageFirstElement();
    this.dataIsModified = true;
    let newItems: any[] = [];

    if (this.useCustomNewRow) {
      newItems.push(this.create());
    }
    else {
      newItems = [{
        newRow: true,
        deletable: true,
        sortable: true
      }];
    }
    this.gridApi.applyTransaction({
      add: newItems,
      addIndex: location,
    });
    this.rowData.unshift(newItems[0]);
    this.addEvent.emit(newItems);
  }

  getLocationFromPageFirstElement() {
    let location = 0;
    if (this.gridApi.paginationProxy.currentPage > 0) {
      location =
        this.gridApi.paginationProxy.currentPage *
        this.gridApi.paginationProxy.pageSize;
    }
    return location;
  }

  create() {
    let newItems = {
      activity: '',
      applicationEquipment: '',
      applicationWaterVolumeMin: 0,
      applicationWaterVolumeMax: 0,
      bufferStrip: '',
      cropType: '',
      driftReduction: '',
      indoorOutdoorApplication: '',
      maxProductApplicationRate: 0,
      season: '',
      applicationSchemePk: 0,
      name: '',
      projectPk: this.selectedProject?.projectPk!,
      compartmentPk: 0,
      applicationSchemeXActiveIngredientRate: [],
      children: [],
      numberOfApplications: 0,
      applicationSchemeHumanHealth: {
        aiConcentration: 0,
        aeConcentration: 0,
        applicationEquipment: '',
        applicationSchemeHumanHealthPk: 0,
        applicationSchemePk: 0,
        bufferStrip: '',
        cropType: '',
        driftReduction: '',
        indoorOutdoorApplication: '',
        maxProductApplicationRate: 0,
        season: '',
        applicationWaterVolumeMax: 0,
        applicationWaterVolumeMin: 0,
        activity: ''
      },
      applicationInterval: 0,
      applicationSchemeHumanHealthPk: 0,
      applicationSchemeXGeography: [],
      newRow: true,
      deletable: true,
      sortable: true
    };
    return newItems;
  }

  fitColumns(gridApi: GridApi) {
    gridApi?.sizeColumnsToFit();
  }

  setColumnSize(columnApi: ColumnApi, gridApi: GridApi) {
    if (this.columnSizeMode === 'autoSize') {
      this.autoSizeAllColumns(columnApi);
    }
    else if (this.columnSizeMode === 'fit') {
      this.fitColumns(gridApi);
    }
  }

  autoSizeAllColumns(columnApi: ColumnApi) {
    // columnApi?.autoSizeColumns(columnApi?.getColumns()?.map(col => col.getId()), false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['detailColumnDefs']) {
      setTimeout(() => {
        this.setColumnSize(this.detailColumnApi, this.detailGridApi);
        this.statusBarDetailComponent?.setSelectionCount(this.detailGridApi.getSelectedRows().length);
      }, 150);
    }
    this.setColumnSize(this.columnApi, this.gridApi);
  }

  onGridReady({ api, columnApi }: GridReadyEvent): void {
    this.gridApi = api;
    this.gridColumnApi = columnApi;
    if (this.autoAdjustColumns)
      this.gridColumnApi.autoSizeAllColumns();
  }

  setDetailCellRendererParams() {
    this.detailCellRendererParams = {
      detailGridOptions: {
        rowSelection: 'multiple',
        rowHeight: 40,
        headerHeight: 37,
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        columnDefs: this.detailColumnDefs,
        groupDisplayType: this.detailGroupDisplayType,
        groupHideOpenParents: this.detailGroupHideOpenParents,
        groupDefaultExpanded: this.detailGroupDefaultExpanded,
        defaultColDef: {
          width: 250,
          wrapHeaderText: true,
          editable: true,
          filter: 'agSetColumnFilter',
          resizable: true,
        },
        onGridReady: ({ api, columnApi }: GridReadyEvent) => {
          this.detailGridApi = api;
          this.detailColumnApi = columnApi;
          this.setColumnSize(this.detailColumnApi, this.detailGridApi);
          this.statusBarDetailComponent = this.detailGridApi.getStatusPanel('statusPanel') as any;
        },
        onSelectionChanged: (event) => {
          this.detailSelectionChangedEvent.emit(this.detailGridApi.getSelectedRows());
          this.statusBarDetailComponent.setSelectionCount(this.detailGridApi.getSelectedRows()?.length);
        },
        onCellValueChanged: (event) => {
          const selectedNode = this.gridApi.getSelectedNodes()[0];
          this.CreateTransaction(selectedNode.id, selectedNode.id, event.oldValue, selectedNode.data);
          const changedData = [event.data];
          event.api.refreshCells();
        },
        masterDetail: true,
        detailRowAutoHeight: true,
        statusBar: this.statusBar,
        context: this,
        components: this.detailFrameworkComponents
      },
      getDetailRowData: (params: any) => {
        const node = this.gridApi?.getRowNode(params.node.parent.id);
        if(!node) return;
        node.setSelected(true, true);
        params.successCallback(params.data.children);
      }

    } as IDetailCellRendererParams;
  }


  isDeletable(nodeID: number): boolean {
    return this.gridApi.getRowNode(nodeID).data.deletable ?? false;
  }

  onFirstDataRendered(params: any): void {
    if (this.autosize) {
      const allColumnIds: any[] = [];
      params.columnApi.getColumns().forEach((column: any) => {
        if (column.colDef.width == undefined) {
          allColumnIds.push(column.colId);
        }
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
    }
    this.onFirstDataRenderedEvent.emit(params);
  }

  public getColumnValue(row: any, columnName: any): any {
    return this.gridApi.rowModel.nodeManager.allNodesMap[row].data[columnName];
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data.is_tracking && !node.data.isExpanded && node.data.isAdmin) {
      return false;
    }
    return true;
  }
  public setSelectionValues(pSelectionObject: SelectionValues) {
    pSelectionObject.row[pSelectionObject.field] = pSelectionObject.value;
  }

  onSelectionChanged(event: any): void {
    this.selectedIds = event.api.getSelectedNodes();
    this.onRowSelectedEmit.emit(event);
  }

  listBoxSelectionChanged(params: any) {
    this.listBoxSelectionChangeEvent.emit(params);
  }

  onClickButton(params: any) {
    this.onClickButtonEvent.emit(params);
  }

  DetailDeleteClicked() {
    const detailSelectedRows = this.detailGridApi.getSelectedRows();
    this.DeleteDetailEvent.emit({ detail: detailSelectedRows, parent: this.gridApi.getSelectedRows()[0] });
  }

  dropDownSelectionChanged(params: any) {
    params.row.editedField = params.field;
    params.row[params.field] = params.value;
    let oldvalue = params.row[params.field];

    if (params.linkParams) {
        const instances = params.params.api.getCellRendererInstances(params.linkParams);
        params.instances = instances;
    }

    this.CreateTransaction(params.id, params.id, oldvalue, params.row);
    if(params?.params?.filterAll){
      params.instances?.forEach(async (instance: any) => {
        if (!params.row[instance.field]) {
            instance.setFilteredValues(null, true, instance.params.options);
        } else {
            instance.setFilteredValues(params.value);
        }
    });
    }

    this.dropDownSelectionChangeEvent.emit(params);
    this.selectionChangedEvent.emit(params.row);
  }

  onCheckboxSelectionChanged(params: any){
    this.CreateTransaction(params.id, params.id, params.value, params.row);
    this.onCheckboxSelectedEvent.emit(params);
  }

  calendarSelectionChanged(params: any) {
    this.calendarSelectionChangeEvent.emit(params);
  }

  getNameForRelatedToColumn(params: any): any {
    const fieldFilter = params.column.colDef.cellRendererParams.fieldFilter;
    const valueFilter = params.node.data[fieldFilter];
    const data = params.column.colDef.filterParams.cellRendererParams.values;
    return data.filter((x: any) => x[fieldFilter] === valueFilter && x.id === params.value)[0].name;
  }

  refreshGrid(): void {
    this.gridApi.redrawRows();
  }

  closeFractionModal(openFractionModal: boolean): void {
    this.openFractionModal = openFractionModal;
  }

  showChangeStatusPanel(): boolean {
    return this.parentInstance.currentCompartment != undefined && this.parentInstance.currentCompartment !== Constants.EFATE_COMPARMENT_IDENTITIES.PEC_SURFACEWATER &&
      this.parentInstance.filteredStatus != undefined && this.selectedIds != undefined && this.selectedIds.length > 0;
  }

  showChangeModelPanel(): boolean {
    return this.parentInstance?.isStep1;
  }

  onbuttonCliked(params: any) {
    this.buttonInputParams = params;
    this.buttonCliked = true;
  }

  displayChange(event: any) {
    this.buttonCliked = event;
  }

  public showDialog(element: any, action: string, nodeId?: any): void {
    Swal.fire({
      title: element.title,
      text: element.content,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (action === 'save') {
          this.save();
        } else if (action === 'delete') {
          this.deleteRowFromGrid(nodeId);

        } else if (action === 'cancel') {
          this.shouldValidate = false;
          this.revertAllChanges();
        }
        else if (action === 'changeStatus') {
          this.changeStatus();
        }
        else if (action === 'clone') {
          this.cloneIsVisible = true;
        }
      } else {
        if (action === 'clone') {
          this.cloneIsVisible = true;
        }
      }
    });
  }

  saveData(transactionList: any[]): Observable<any[]> {
    this.saveEvent.emit(transactionList);
    return of(this.transactionList);
  }

  save(): void {
    this.isSaving = true;
    this.saveData(this.transactionList).subscribe({
      next: () => {
        this.afterSaveSuccess();
      }
      , error: (error) => {
        this.afterSaveError(error)
      }
    },
    );
    this.editing = false;
  }

  afterSaveSuccess(): void {
    this.transactionList = [];
    this.selectedIds = null;
    this.isSaving = false;
  }

  afterSaveError(error: { error: { Value: { detail: any; }; }; }) {

    Swal.fire({
      text: error.error.Value.detail,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error',
    });
    this.selectedIds = null;
    this.isSaving = false;
  }

  public changeStatus(): void {
    this.selectedIds.forEach((element: { data: { isAdmin: any; isEditor: any; status: string; }; id: any; }) => {
      if ((element.data.isAdmin) || (element.data.isEditor && element.data.status === Constants.CONST_STATUS_PENDING_FOR_REVISION)) {
        if (this.selectedInputtingStatus && this.selectedInputtingStatus.value > 0) {
          this.gridApi
            .getRowNode(element.id)
            .setDataValue('status', this.selectedInputtingStatus.text);
        }
        if (this.selectedRegistrationStatus && this.selectedRegistrationStatus.datafieldValuePk > 0) {
          this.gridApi
            .getRowNode(element.id)
            .setDataValue('registrationStatus', this.selectedRegistrationStatus.value);
        }
      }
    });
    this.gridApi.redrawRows();
  }

  onBtExport() {
    this.gridApi.exportDataAsExcel({
      processCellCallback: (params: any) => {
        if (this.columnsNotIncludedExcelExport.indexOf(params.column.colId) === -1) {
          if (this.columnsForValidateInExport.indexOf(params.column.colId) > -1) {
            params.value = this.getNameForRelatedToColumn(params);
          }
          return params.value;
        }
      },
    });
  }

  onButtonExpandClick(params: any) {
    this.buttonExpandParams.emit(params);
  }

  onRowClicked(event: RowClickedEvent) {
    this.rowClickedEvent.emit(event);
  }

  onAutoCompleteSelected(params: any) {
    this.onAutoCompleteChanged.emit(params);
  }

  public onInputNumberBlur(params: any): void {
    this.onInputNumberChanged.emit(params);
  }

  onModelOptionsChanged(event: any) {
    this.onModelOptionsChangedEvent.emit(event);
  }

  public setChangeValues(newData: any): void {
    let oldValue = newData.oldValue;
    this.CreateTransaction(
      newData.row.id,
      newData.id,
      oldValue,
      newData.row);
    this.onBlurInputText.emit(newData);
  }

  public onCopyDataset(datasetToImport: ImportDataSet): void {
    this.onCopyDatasetEmit.emit(datasetToImport);
  }

  public deleteRowFromMainGrid(): void {
    const selectedData = this.gridApi.getSelectedRows();
    const selectedNodes = this.gridApi.getSelectedNodes();

    this.gridApi.applyTransaction({ remove: selectedData });
    const index = this.transactionList?.findIndex(
      (obj) => obj.getRowUniqueID() === selectedNodes[0].id
    );

    if (selectedData[0]['newRow'] === true) {
      if (index > -1) {
        this.transactionList.splice(index, 1);
      }
    } else {
      this.deletedData.emit(selectedNodes[0].data);
      const gridTransaction: GridTransaction = this.newTransaction(selectedNodes[0].id ?? "", GridTransaction.delete);
      gridTransaction.setRow(selectedNodes[0].data);
      this.transactionList.push(gridTransaction);
    }
    this.deleteEvent.emit(this.transactionList);
    let rowsDeletedIds = this.transactionList.filter((t: any) => t.transactionType == Constants.TRANSACTION_TYPE.DELETE)?.map((x: any) => x.rowUniqueID)?.flat();
    this.transactionList = this.transactionList.filter((t: any) => t.transactionType != Constants.TRANSACTION_TYPE.DELETE && !t.rowUniqueID.includes(rowsDeletedIds));
  }

  deleteRowFromMainByMultiSelection(nodeID: any): void {
    const rowToDelete = this.gridApi.getRowNode(nodeID.node.id);
    this.gridApi.applyTransaction({ remove: [rowToDelete.data] });

    const index = this.transactionList?.findIndex(
      (obj) => obj.getRowUniqueID() === rowToDelete.id
    );
    this.deletedData.emit(rowToDelete.data);
    const gridTransaction: GridTransaction = this.newTransaction(rowToDelete.id ?? "", GridTransaction.delete);
    gridTransaction.setRow(rowToDelete.data);
    this.transactionList.push(gridTransaction);
    this.deleteEvent.emit(this.transactionList);
    let rowsDeletedIds = this.transactionList.filter((t: any) => t.transactionType == Constants.TRANSACTION_TYPE.DELETE)?.map((x: any) => x.rowUniqueID)?.flat();
    this.transactionList = this.transactionList.filter((t: any) => t.transactionType != Constants.TRANSACTION_TYPE.DELETE && !t.rowUniqueID.includes(rowsDeletedIds));
  }

  public setLastRowSelected(field: string) {
    const lastrow = this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFirstDisplayedRow());
    lastrow.data[field] = true;
    this.gridApi.applyTransaction({ update: [lastrow.data] })!;
  }

  public refreshSelectedValues(field: string): void {
    const selected: IRowNode[] = [];
    const deselected: IRowNode[] = [];

    this.gridApi.forEachNode((node: any) => {
      const data = node.data;
      if (data && data[field])
        selected.push(node);
    });

    this.gridApi.setNodesSelected({ nodes: selected, newValue: true });
    this.gridApi.setNodesSelected({ nodes: deselected, newValue: false });
  }

  public selectOrUnselectRow(index: number, select: boolean): void {
    if (!this.gridApi) return;
    const rowNode = this.gridApi.getRowNode(index);

    if (select)
      this.gridApi.setNodesSelected({ nodes: [rowNode], newValue: true });

    this.gridApi.setNodesSelected({ nodes: [rowNode], newValue: false });
  }

  public onRowGroupOpened(event: RowGroupOpenedEvent) {
    this.onRowExpanded.emit(event);
  }

  public onRowSelected(event: RowSelectedEvent): void {
    if (!event.event) return;
    this.onSelectedRowEvent.emit(event);
  }
}
