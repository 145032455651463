export interface EndpointDatasetGridTransaction {
  transactionType: number;
  rowUniqueID:     string;
  row:             Row;
}

export interface Row {
  dataSetPk?:    number;
  newRow?:          boolean;
  deletable?:       boolean;
  sortable?:        boolean;
  moleculePk?:      number;
  modified?:        boolean;
  editedField?:     string;
  originalValue?:   number;
  description?:     string;
  isExpanded?:      boolean;
  calculatorGroup?: string;
  useInProject?:    boolean;
  originalProject?: number;
  createdBy?:       string;
  metabolitePk?:    number;
  substanceType?:   string;
}

export interface EndpointDatasetParamsData {
  dataSetPk?:          number;
  substanceType?:      string;
  substanceName?:      string;
  moleculePk?:         number;
  metabolitePk?:       number;
  formulationPk?:      number;
  countryPk?:          number;
  regionPk?:           number;
  agencyPk?:           number;
  description?:        string;
  archive?:            string;
  deletable?:          boolean;
  activeIngredientPK?: number;
  geographyType?:      string;
  automatic?:          boolean;
  geographyName?:      string;
  activeIngredient?:   string;
  hasEndpoints?:       boolean;
  chemicalType?:       number;
  source?:             string;
  projectPk?:          number;
  isCore?:             boolean;
  isExpanded?:         boolean;
  modified?:           boolean;
  newRow?:             boolean;
  useInProject?:       boolean;
  originalProject?:    number;
  createdBy?:          string;
  compoundPk?:         number;
}

export enum EndpointDatasetSaveStatus {
  NormalSave = 'NormalSave',
  Saving     = 'Saving',
  Saved      = 'Saved'
}
