<div class="grid">
  <div class="col-12 mt-2">
    <app-button [outlined]="true" severity="{{activeButtonColor}}" label="{{buttonLabel}}"
      (onClickEvent)="onActiveIngredientClick()"></app-button>
    <app-button [outlined]="true" severity="{{metaboliteButtonColor}}" label="Metabolites"
      (onClickEvent)="onMetaboliteClick()"></app-button>
    <app-dropdown [filter]="true" [options]="metabolitesOptions" optionLabel="metaboliteName"
      placeholder="Select Metabolite" *ngIf="metaboliteSelected"
      (onChangeEvent)="getSelectedMetabolite($event)"></app-dropdown>
    <p-checkbox *ngIf="selectedMetabolitePkValue !== 0" [ngClass]="isProjectOwnershipValid? '': 'disabled'"  label="N/A" name="N/A" [binary]="true" [(ngModel)]="excludeMetaboliteFromAI"  (onChange) = "onChangeExcludeMetabolite($event)" />
    <p-checkbox *ngIf="selectedMetabolitePkValue !== 0 && !excludeMetaboliteFromAI && showDaughter" [ngClass]="isProjectOwnershipValid && !excludeMetaboliteFromAI ? '': 'disabled'"  label="Daughter" name="Daughter" [binary]="true" [(ngModel)]="daugtherOptionSelected"  (onChange)="onChangeSelectedDaugther($event)" />
    <p-checkbox *ngIf="selectedMetabolitePkValue !== 0 && !excludeMetaboliteFromAI && showGranddaughter" [ngClass]="isProjectOwnershipValid && !excludeMetaboliteFromAI ? '': 'disabled'"  label="Granddaughter" name="Granddaughter" [binary]="true" [(ngModel)]="granddaugtherOptionSelected"  (onChange)="onChangeSelectedGranddaugther($event)" />
  </div>
</div>