import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Constants } from '../../utils/constants';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { OrderByPipe } from '../../pipes/order-by.pipe';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.css']
})
export class DropdownListRenderer implements ICellRendererAngularComp {

  public params: any;
  public options: Array<any> = [];
  public optionsParam: Array<any> = [];
  public optionValue!: string;
  public optionLabel!: string;
  public filterable: boolean = true;
  public orderBy: string = 'name';
  public disabled: boolean = false;
  public defaultItem: any;
  public selectedValue: any;
  public field!: string;
  public parent: any;
  public isRequired: boolean = false;
  public showClear: boolean = false;
  public isValid: boolean = true;
  public newOptions?: Array<any>;
  public isGroupRendered?: boolean;

  private optionsToRemove?: Array<any> = [];
  private fieldToRemove?: string

  width!: number;
  height: string = '250px';
  link: string[] = [];
  filteredBy?: any[];

  public placeholder: string = '';
  constructor(private orderByPipe: OrderByPipe) { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.isGroupRendered = params.node.group;
    this.params = params;
    this.defaultItem = params?.defaultItem
    this.parent = params.context;
    this.filteredBy = params.filteredBy;
    this.link = params.link;
    this.selectedValue = params?.value ?? params?.defaultItem;
    this.options = params.options ? this.defaultValueProcess([...params.options]) : undefined;
    this.placeholder = params.placeholder !== undefined ? params.placeholder : this.placeholder;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.disabled = params.disabled;
    this.field = params.colDef.field;
    this.orderBy = params.orderBy ?? this.orderBy;
    this.showClear = params.showClear ?? this.showClear;
    this.height = params.height ?? this.height;
    this.optionsToRemove = params.optionsToRemove;
    this.fieldToRemove = params.fieldToRemove;
    this.isFilterable();
    this.manageIsRequired(params.isRequired);
  }

  isFilterable() {
    this.filterable = !Constants.COLUMNS_NO_FILTREABLES.includes(this.field) && this.params.useFilter;
  }

  defaultValueProcess(options: any, avoidFilter: boolean = true): any {
    if (this.params.data?.newRow && !this.params.value) {
      const index = this.defaultValueIndex(options);
      if (index >= 0) {
        this.selectedValue = options[index].value ? options[index].value.replace(Constants.defaultValueFlag, '')
          : options[index].replace(Constants.defaultValueFlag, '');
        if (options[index].value)
          options[index].value = this.selectedValue;
        else
          options[index] = this.selectedValue;
      }
    }

    setTimeout(() => {
      if(this.link) this.dropdownChange(this.selectedValue);
    }, 1);
    return this.filteredBy && avoidFilter ? undefined : this.orderByPipe.transform(options, this.orderBy);
  }

  defaultValueIndex(pOptions: any[]) {
    return pOptions?.findIndex((x) => {
      if (x.isDefault && x.isDefault === true)
        return true;

      if (typeof x === 'string' && x.includes(Constants.defaultValueFlag))
        return true;

      return false;
    });
  }

  onChange({ value }: DropdownChangeEvent): void {
    this.dropdownChange(value);
  }

  dropdownChange(value : any) {
    if(this.isGroupRendered) return;
    this.params.data[this.field] = value;
    this.params.data.modified = true;
    try{
      this.parent.dropDownSelectionChanged({
        linkParams: this.link ? { columns: [...this.link], rowNodes: [this.params.node] } : undefined,
        field: this.field,
        id: this.params.node.id,
        row: this.params.data,
        sourceValues: this.options,
        value: this.selectedValue,
        params: this.params,
      });
    }
    catch(e){};
    try{
      this.params.context.componentParent.dropDownSelectionChanged({
        linkParams: this.link ? { columns: [...this.link], rowNodes: [this.params.node] } : undefined,
        field: this.field,
        id: this.params.node.id,
        row: this.params.data,
        sourceValues: this.options,
        value: this.selectedValue,
        params: this.params,
      });
    }
    catch(e){};
  }
  setFilteredValues(value: any, filterAll: boolean = false, newValues: any[] = []) {
    if(!value) return;

    if(this.filteredBy && !filterAll) {
      let filteredValues = [...this.params.options];
      this.filteredBy.forEach(filter => {
        const currentValue = this.params.data[filter.field];
        if(currentValue) {
          filteredValues = filteredValues.filter((option: any) =>
            option.relatedFields.some((field: any) => field.field === filter && field.relations.map((relation: any)=> relation.name).includes(currentValue))
          );
        }
      });
      this.options = this.defaultValueProcess(filteredValues, false);
      setTimeout(() => {
        this.dropdownChange(value);
      }, 1);
    }

    if(filterAll)
      this.options = newValues;
  }

  private manageIsRequired(isRequired: boolean | undefined): void {
    if(isRequired != undefined)
      this.isRequired = isRequired;

    if((this.selectedValue == undefined || this.selectedValue == null || this.selectedValue === '') && this.isRequired)
      this.isValid = false;
  }

  public onClick(): void {

    if( !this.fieldToRemove ) return;

    for (let i = this.options.length - 1; i >= 0; i--) {
      if (this.optionsToRemove?.includes(this.options[i][this.fieldToRemove!])) {
        this.options.splice(i, 1);
      }
    }
  }
}
