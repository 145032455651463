import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject, computed } from '@angular/core';
import { EEAGapPWCSurfaceWaterColdef } from './eea-gap-surfacewater-pwc.coldef';

import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { CropEvents } from 'src/app/shared/models/pwc/crop-events';
import { ApplicationMethod } from 'src/app/shared/models/pwc/application-method';
import { firstValueFrom, take } from 'rxjs';

import { ApplicationScheme, ApplicationSchemeCropState, ApplicationSchemeXApplication } from 'src/app/shared/models/application-scheme';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectionValues } from 'src/app/shared/models/selectionValues';
import { GridLogicService } from 'src/app/shared/components/grid/grid.logic.service';
import { SimulationType } from 'src/app/shared/models/pwc/simulation-type';
import { CropInterceptionStep1And2 } from 'src/app/shared/models/crop-interception';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGapApiService } from '../eea-gap.api.service';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { SaveStructure } from 'src/app/shared/models/transaction-type';
import { EeaGapSurfacewaterPwcScenariosLogicService } from './eea-gap-surfacewater-pwc-scenarios/eea-gap-surfacewater-pwc-scenarios.logic.service';
import { SelectedScenariosByRow } from 'src/app/shared/models/pwc/scenarios.interface';

@Component({

  selector: 'app-eea-gap-surfacewater-pwc',
  templateUrl: './eea-gap-surfacewater-pwc.component.html',
  styleUrls: ['./eea-gap-surfacewater-pwc.component.css']
})
export class EEAGapSurfacewaterPwcComponent implements OnInit, OnChanges {

  @ViewChild('grid') grid!: GridComponent;

  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() useModels!: boolean;
  @Input() subTabMenuService!: TabMenuLogicService;

  @Output() public isValid = new EventEmitter<boolean>();

  columnsDef: any;
  saveRef?: string;
  public detailsColumnDef: any = [];
  rowData: any;
  compartmentList!: any[];
  datafieldOptions!: any[];
  geographies!: Catalog[];
  cropInterceptionsStep1And2!: CropInterceptionStep1And2[];
  applicationSchemeCropsStates: ApplicationSchemeCropState[] = [];
  missingDataRows: any[] = [];
  cropListMatchings!: CropListMatching[];
  numbersOfApplication!: any[];
  cropEvents: CropEvents[] = [];
  simulationTypes: SimulationType[] = [];
  applicationMethods: ApplicationMethod[] = [];
  isMasterDetail: boolean = true;
  isloading: boolean = false;
  compartment: string = Constants.COMPARTMENTS.SURFACE_WATER;
  show: boolean = true;
  pwcFlag: boolean = true;
  selectedGeography: string | undefined = "";
  destroyRef = inject(DestroyRef);
  isRuning: boolean = true;
  autoGroupColDef: any = {
    headerValueGetter: (params: { colDef: { headerName: any; }; }) => `${params.colDef.headerName}`,
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,

    }
  };
  public defaultColDef: ColDef = { flex: 2 };
  @Input() selectedProject?: Project;
  compartmentPk!: number;
  childrens: any;
  highlightRow: boolean = false;
  qcFinish: boolean = false;
  columnSizeMode:string = "fit";
  public selectedRow?: ApplicationScheme;
  public showScenarios = computed(() => this.scenariosLogicService.visible());
  rowHeight: number = 80;
  constructor(private gapLogicSWService: EEAGapLogicService,
    private gapLogicApi: EEAGapApiService,
    private gridService: GridLogicService,
    private EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService,
    private eEAGapPWCSurfaceWaterColdef: EEAGapPWCSurfaceWaterColdef) {

  }

  private scenariosLogicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.initSubscribes();
      this.IniValues(changes);
    }
  }
  async ngOnInit(): Promise<void> {
    this.initSubscribes();
    this.isloading = true;
    await this.scenariosLogicService.getCropsXRegion();
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.menuService.setSuccess(true)
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.subTabMenuService.setSuccess(true);
    });
  }

  onAddDetail(event: any) {
    let row = event.parent;
    if (!row.children) row.children = [];
    const newChildren: ApplicationSchemeXApplication = {
      application_scheme_x_application_pk: event.index,
      application_date: event.application_date,
      application_method: 0,
      application_number: event.application_number,
      application_scheme_pk: event.application_scheme_pk,
      crop_interception: 0,
      day: event.day,
      days_since: 0,
      depth: event.depth,
      drift: event.drift,
      earliest_bbch: event.earliest_bbch,
      efficiency: event.efficiency,
      min_application_interval: event.min_application_interval,
      month: event.month,
      t_band_split: event.t_band_split,
      year: event.year
    };
    row.children.unshift(newChildren);
    this.gridService.addDetailTransaction([newChildren]);
    this.grid.gridApi.redrawRows([newChildren]);
  }

  dropDownSelectionChanged(event: any) {
    if (event.field === Constants.FIELD_NAMES.DATE_TYPE) {
      this.cropEvent(event);
    }
    if (event.field === Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD) {
      this.setApplicationMethodEditable(event);
    }
  }

  setApplicationMethodEditable(event: any) {
    const childrens = this.detailsColumnDef?.flatMap((node: { children: any; }) => node.children);
    let depth = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_DEPTH) as any;
    let tband = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.PWCControlsValidations.TBAND) as any;
    if (event.field === Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD) {
      switch (event.value) {
        case Constants.FIELD_NAMES.APPLICATION_METHOD_DEPTH:
          depth.hide = false;
          tband.hide = true;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_ABOVE_CROP:
          depth.hide = true;
          tband.hide = true;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_BELOW_CROP:
          depth.hide = true;
          tband.hide = true;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_DECREASING:
          tband.hide = true;
          depth.hide = false;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_INCREASING:
          tband.hide = true;
          depth.hide = false;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_UNIFORM:
          tband.hide = true;
          depth.hide = false;
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_T_BAND_SPLIT:
          tband.hide = false;
          depth.hide = false;
          break;
      }
    }

    this.grid?.gridApi.refreshCells();
    this.grid.gridApi.redrawRows();
  }

  cropEvent(event: any) {
    const childrens = this.detailsColumnDef?.flatMap((node: { children: any; }) => node.children);
    let daySince = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.COLUMN_HEADER_NAMES.DAYS_SINCE) as any;
    let crop = this.columnsDef?.find((x: { headerName: string; }) => x.headerName === Constants.COLUMN_HEADER_NAMES.CROP_EVENT) as any;
    let appDate = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.PWCControlsValidations.ApplicationDate) as any;
    var node1 = event.params.api.getDisplayedRowAtIndex(event?.id)!;
    if (event.dateType === Constants.FIELD_NAMES.DATE_TYPE ||
      event.field === Constants.FIELD_NAMES.DATE_TYPE && event.value === Constants.DATE_TYPE_VALUES.RELATIVE) {
      if (crop && appDate) {
        this.grid?.gridColumnApi.setColumnVisible(crop?.field, true);
        appDate.hide = true;
        daySince.hide = false;

        this.isExpanded(node1);
      }
    } else {
      appDate.hide = false;
      daySince.hide = true;

      this.grid?.gridColumnApi.setColumnVisible(crop?.field, false);
      this.isExpanded(node1);
    }
    this.grid?.gridApi.refreshCells();
    this.grid.gridApi.redrawRows();
  }
  isExpanded(node: any) {
    if (node?.expanded) {
      this.grid.gridApi.setRowNodeExpanded(node, false);
    } else {
      this.grid.gridApi.setRowNodeExpanded(node, true);
    }
  }

  saveData(dataTransaction: any[]) {
    if (dataTransaction === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;
    this.gapLogicSWService.setParametersToNewRowsPWC(dataTransaction, this.compartmentPk, false, this.selectedProject?.projectPk)
    this.gapLogicSWService.transformPWCDetailsObjectToApplicationSchemeXApp(dataTransaction);
    this.gapLogicSWService.setAIRateFromPWCObject(dataTransaction);

    const transformedData: SaveStructure[] = this.gapLogicSWService.transformToSaveStructureObject(dataTransaction);

    this.gapLogicApi.savePWC(transformedData).pipe(take(1)).subscribe({
      next: () => {
        this.grid.afterSaveSuccess();
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
      },
      error: (err: any) => {
        console.warn(err);
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
      }
    });
    this.grid.transactionList = [];
  }


  getColumnDefPwc(option: any) {
    EEAGapPWCSurfaceWaterColdef.getPWCDetailsColumnsDefination(option, this.isProjectOwnershipValid);
    return this.eEAGapPWCSurfaceWaterColdef.getPWCColumnsDefinition(option, this.isProjectOwnershipValid);
  }

  onModelOptionsChanged(models: string[]) {
    if (models.map((x: string) => x === Constants.MODELS.PWC)[0]) {
      this.pwcFlag = true;
      return;
    }

    if (this.grid) {
      const geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK, Constants.CROP_GEOGRAPHIES.USA];
      let selectedGeography = this.geographies.filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => this.selectedProject?.countryPk === x.key || this.selectedProject?.regionPk === x.key)?.name;
      this.selectedGeography = selectedGeography;
      let columns: any[] = this.gapLogicSWService.getDisplayedColumnsByModel(models, selectedGeography!);
      let displayedColumns = this.grid?.gridColumnApi.getColumns();
      let fixedColumns = models.includes(Constants.MODELS.SWASH) || models.includes(Constants.MODELS.SWAN) || models.includes(Constants.MODELS.MACRO_GW) || models.includes(Constants.MODELS.PEARL) || models.includes(Constants.MODELS.PELMO) ? ['deleteButton', 'appWindow'] : ['deleteButton'];
      let columnsToShow: string[] = [...Constants.GAP_CORE_FIELDS, ...new Set(columns), ...this.gapLogicSWService.getFixedColumns(displayedColumns, fixedColumns)];
      columnsToShow = this.removeColumnsByModel(models, columnsToShow);
      displayedColumns.map((column: any) => {
        this.grid?.gridColumnApi.setColumnVisible(column.colId, columnsToShow.includes(column.getColDef().field));
      });
      this.grid?.gridApi.refreshCells();
    }
  }

  removeColumnsByModel(models: string[], columnsToShow: string[]) {
    return this.removeUKColumns(models, columnsToShow);
  }

  removeUKColumns(models: string[], columnsToShow: string[]) {
    return (JSON.stringify(models) === JSON.stringify(new Array(Constants.MODELS.UK))) ? columnsToShow.filter((x: string) => x !== 'geographies') : columnsToShow;
  }

  IniValues(changes: SimpleChanges) {
    let promises = [
      this.gapLogicSWService.getCompartments(),
      this.gapLogicSWService.getStep1n2Interceptions(),
      this.gapLogicSWService.getCropListMatchings(),
      this.gapLogicSWService.getPWCCropEvents(),
      this.gapLogicSWService.getPWCSimulationTypes(),
      this.gapLogicSWService.getPWCApplicationMethods(),
      this.gapLogicSWService.numerOfApplications
    ]
    Promise.all(promises)
      .then(async (results) => {
        this.compartmentList = results[0];
        this.cropInterceptionsStep1And2 = results[1];
        this.cropListMatchings = results[2];
        this.cropEvents = results[3].map((crop: any) => { return { id: crop, name: crop } });
        this.simulationTypes = results[4].map((methods: any) => { return { typeName: methods } });
        this.applicationMethods = results[5].map(x => { return { id: x, name: x } });
        this.numbersOfApplication = results[6];

        await this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCompartmentPk());
        this.detailsColumnDef = EEAGapPWCSurfaceWaterColdef.getPWCDetailsColumnsDefination(this, this.isProjectOwnershipValid);
      })

  }
  isCellEditable(pParams: any): boolean {
    let result: boolean = false;
    if (pParams.colDef.field == Constants.FIELD_NAMES.DEPTH) {
      if (pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_DEPTH
        || pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_T_BAND_SPLIT
        || pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_DECREASING
        || pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_INCREASING
        || pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_UNIFORM) {
        result = true;
      }
      else {
        result = false;
      }
    }
    if (pParams.colDef.field == Constants.FIELD_NAMES.T_BAND_SPLIT) {
      if (pParams.data.applicationMethod == Constants.FIELD_NAMES.APPLICATION_METHOD_T_BAND_SPLIT) {
        result = true;
      }
      else {
        result = false;
      }
    }
    return result;
  };

  getColDefFilteredBySelectedModels() {
    if (!this.selectedProject) return;
    const geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK, Constants.CROP_GEOGRAPHIES.USA];
    let selectedGeography = this.geographies.filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => this.selectedProject?.countryPk === x.key || this.selectedProject?.regionPk === x.key)?.name;
    const colDefParams = {
      applicationMethods: this.applicationMethods,
      geographySelected: selectedGeography,
      cropListMatching: this.cropListMatchings
    };
    return this.getColumnDefPwc(colDefParams);
  }

  listBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    let oldValue: any = params.data[Constants.PWC_FIELD_NAMES.SIMULATION_TYPES];
    params.data.simulationTypes = params.selectedValues.map((x: any) => {return { typeName: x.typeName }});
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);  

    let isTpezWpezSelected = params.selectedValues?.find((x: any) => x.typeName == Constants.PWC_SIMULATION_TYPES.USEPA_TPEZ_WPEZ);
    if(!isTpezWpezSelected){
      params.data.useSpraydriftBuffersForTpez = false;
    }

    this.grid.gridApi.redrawRows(); 
  }

  public async onCheckboxSelectedChanged(event: any): Promise<void> {
    if(event.field == Constants.PWC_FIELD_NAMES.ADJUST_APPLICATION_DATES_IF_RAINING && event.value == false){
      event.row.intolerableRainCm = null;
      event.row.intolerableRainWindowDays = null;
      event.row.optimumApplicationWindowDays = null;
      event.row.minimumDaysBetweenApplications = null;
    }
    this.grid.gridApi.redrawRows(); 
  }

  getCompartmentPk() {
    this.compartmentPk = Number(this.compartmentList?.filter((x: any) => x.compartment == Constants.COMPARTMENTS.SURFACE_WATER)?.map(x => x.endpointCompartmentPk).flat());
    return this.compartmentPk;
  }

  onDelete() {
    this.gridService.removeTransaction(this.grid.transactionList);
  }
  onDeleteDetails(event: any) {
    let deletePKs = event.detail.map((x: { application_scheme_x_application_pk: any; }) => x.application_scheme_x_application_pk);
    event.parent.children = event.parent.children.filter((x: { PK: any; }) => !deletePKs.includes(x.PK));
    let indexToRemove = event.parent.children.findIndex((x: { PK: any; }) => deletePKs.includes(x.PK));
    event.parent.haveChanges = true;
    event.parent.children.splice(indexToRemove, 1);
    this.gridService.removeDetailTransaction(event.detail);
    this.removedDetailsRow(deletePKs, event);
    this.grid.gridApi.redrawRows();

  }

  deleteRow(event: any) {
    this.gridService.removeTransaction(this.grid.transactionList);
    if (event) { this, this.gapLogicApi.savePWC(this.grid.transactionList).pipe(take(1))
        .subscribe(() => { });
    }
    if (this.grid.gridApi.getDisplayedRowCount() === 1) {
      this.grid.gridApi.redrawRows();
    }
  }

  transformDataPWC(data: any, isInverseModeling: boolean) {
    data?.map((row: any) => {
      row.tillage = row.tillage ? 'Yes' : (row.tillage != undefined ? 'No' : null);
      row.geographies = [];
      if (row?.applicationSchemeXGeography)
        row?.applicationSchemeXGeography.map((geography: any) => {
          const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
          if (geo) {
            row.geographies.push(geo);
          }
        });
      if (!isInverseModeling) {
        if (row.aplicationSchemeXApplication)
          row?.applicationSchemeXActiveIngredientRate.map((rate: any) => {
            let application = row?.applicationSchemeXApplication.find((a: any) => a.application_scheme_x_application_pk = rate.application_scheme_x_application_pk);
            if (application) {
              application[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
            }
          });
        row.children = row.applicationSchemeXApplication;
      }
    });

    this.rowData = data;
  }

  onCalendarSelectionChanged(event: any): void {
    let parentRowNode = this.grid.gridApi.getRowNode(event.id);
    let appDate: ApplicationSchemeXApplication = {
      application_date: event.value
    }
    parentRowNode.data.ApplicationSchemeXApplication = [appDate];
    let oldvalue = event.row[event.field];
    event.row[event.field] = event.value;
  }

  removedDetailsRow(pks: number[], row: any) {
    pks.map((x: number) => {
      this.gapLogicApi.DeleteDetailsPWC(x).pipe(take(1)).subscribe({
        next: () => {
          this.grid.afterSaveSuccess();
          this.EEAGAPMenuLogicService.setSuccess(true);
          this.grid.gridApi.redrawRows([x]);
        },
        error: (err: any) => {
          console.warn(err);
          this.EEAGAPMenuLogicService.setSuccess(false);
        }
      });
    });
  }

  getApplicationDetails(applicationSchemePk: number): ApplicationSchemeXApplication[] {
    let app: ApplicationSchemeXApplication[] = [];
    this.gapLogicSWService.getPwcApplicationSchemeXApplicationPk(applicationSchemePk).then((appSchemeXApp: ApplicationSchemeXApplication[]) => {
      app = appSchemeXApp;
    });
    return app;
  }

  async getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    let children: ApplicationSchemeXApplication[] = [];
    if (projectPk != 0) {
      const columnsDefinition = EEAGapPWCSurfaceWaterColdef.getPWCDetailsColumnsDefination(this, this.isProjectOwnershipValid);;
      this.isloading = true;

      await firstValueFrom(this.gapLogicApi.getApplicationSchemesByProjectAndCompartmentPWC(projectPk, compartmentPk).pipe(take(1)))
        .then((applicationSchemes) => {
          if (!this.selectedProject) return;
          this.gapLogicSWService.addActiveIngredientsRatesToGridPWC(applicationSchemes, columnsDefinition, false, this.selectedProject, this.isProjectOwnershipValid, this.compartment).then(() => {
            if (applicationSchemes != undefined)
              this.rowData = this.gapLogicSWService.rowPWC;
                  
            this.columnsDef = this.eEAGapPWCSurfaceWaterColdef.getPWCColumnsDefinition(this, this.isProjectOwnershipValid);
            this.detailsColumnDef = columnsDefinition;
            this.show = false;

          });
        });
    }
  }

  newRowAdded(newItems: any[]) {
    this.enableControls(false);
  }

  public onBlurInputText({row} : {row:any}): void {
    this.checkIfApplicationSchemeIsValid(row.name, row.applicationSchemePk);
  }

  private async checkIfApplicationSchemeIsValid(applicationSchemeName: string | undefined, applicationSchemePk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some((row: any) => row.name == undefined || row.name == '');
    const transactionOcurrences = this.gapApplicationSchemeLogicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.gapApplicationSchemeLogicService.duplicateDataInRecord(transactionOcurrences);
    applicationSchemePk = applicationSchemePk ?? -1

    if(applicationSchemeName != undefined && applicationSchemeName != '' ){
      if(duplicateDatasetsInTransaction)
        Utils.showErrorMessage('The Application Scheme name already exists.','Please use another name');
    }

    if(invalidRows || duplicateDatasetsInTransaction)
      this.enableControls(false);
    else
      this.enableControls(true);
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
    this.grid.columnDefs.find((c: any) => c.colId == 'delete').cellRendererParams.disabled = !enable;
    this.grid.gridApi.setColumnDefs(this.columnsDef);
  }

  public onSelectScenariosClick(event: any): void {
    this.scenariosLogicService.currentRowId.set( event.rowNode.rowIndex );
    this.scenariosLogicService.visible.set( true );
    this.selectedRow = event.data;
  }

  public onSelectScenarios( selectedScenariosByRow: SelectedScenariosByRow ): void {
    if( !this.grid ) return;
    const { rowId, selectedScenariosIds} = selectedScenariosByRow;
    const rowNode = this.grid.gridApi.getRowNode(rowId);
    const { id } = rowNode;
    rowNode.data.cropXRegionPks = selectedScenariosIds;
    this.grid.CreateTransaction(id, id, undefined, rowNode.data);
  }

}
