import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-buttons-panel',
  templateUrl: './action-buttons-panel.component.html',
  styleUrls: ['./action-buttons-panel.component.css']
})
export class ActionButtonsPanelRenderer {

  @Input() isSaving!: boolean;
  @Input() useSaveAction: boolean = false;
  @Input() useAddAction: boolean = false;
  @Output() onBtExportEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBtAddEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBtSaveEvent: EventEmitter<any> = new EventEmitter<any>();
  

  onBtExport() {
    this.onBtExportEvent.emit();
  }

  add() {
    this.onBtAddEvent.emit()
  }

  save() {

    this.onBtSaveEvent.emit();
  }
}