import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { HHRAGapLogicService } from './hhra-gap.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';

@Component({
  selector: 'app-hhra-gap',
  templateUrl: './hhra-gap.component.html',
  styleUrls: ['./hhra-gap.component.css']
})

export class HHRAGapComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  activeItem!: any;
  items: any[] = []
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  loading: boolean = false;
  wizardLoading: boolean = false;
  nextIndex?: number;
  message!: Message;
  isSetupValid: boolean = false;
  public isApplicationSchemeValid: boolean = true;

  constructor(public HHRAGapLogicService: HHRAGapLogicService){}

  ngOnInit(): void {
    let isInit = true;
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if(!this.activeItem?.index)
        this.menuService.setSuccess(true)
    });
    this.HHRAGapLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.HHRAGapLogicService.setSuccess(true));
    this.HHRAGapLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeItem: CustomTabMenuItem) => this.activeItem = activeItem);
    this.HHRAGapLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((success: boolean)  => {
      this.wizardLoading = false;
      if(success) {
        const nextItem = this.HHRAGapLogicService.items.find((i: CustomTabMenuItem) => i.index == this.nextIndex);
        if(nextItem && !isInit){
          this.HHRAGapLogicService.setActiveItem(nextItem);
        }
      }
      isInit = false;
    });
  }

  onActiveIndexChange(index: number){
    this.nextIndex = index;
    this.HHRAGapLogicService.setActiveIndexChanged(true, index);
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  public getIsApplicationSchemeValid(isValid: boolean): void {
    this.isApplicationSchemeValid = isValid;
    this.isValid.emit(isValid);
  }
}