import { Injectable } from '@angular/core';
import { ButtonExpandGridRenderer } from '../../renderers/button-expand-grid/button-expand-grid.component';
import { DropdownListRenderer } from '../../renderers/dropdown-list/dropdown-list.component';
import { InputTextRenderer } from '../../renderers/input-text/input-text.component';
import { ImportDatasetRenderer } from '../../renderers/import-dataset/import-dataset.component';
import { EndpointDatasetLogicService } from './endpoint-dataset.logic.service';
import { StatusFlagRenderer } from '../../renderers/status-flag/status-flag.component';
import { DeleteButtonRenderer } from '../../renderers/delete-button/delete-button.component';
import { DatasetReferenceIconRenderer } from '../../renderers/dataset-reference-icon/dataset-reference-icon.component';
import { Constants } from '../../utils/constants';
import { UserLogicService } from '../../services/user.logic.service';
import { Project } from '../../models/project';

@Injectable({
  providedIn: 'root',
})

export class EndpointDatasetColDefService {

  constructor(private readonly logicService: EndpointDatasetLogicService,
    private readonly userLogicService: UserLogicService,
  ) {}

  public getColumnsDefinition(isProjectOwnershipValid: boolean, projects: Project[], environmentalAssessment: string = ''){
    return [
      {
        colId: 'show',
        minWidth: 65,
        width: 65,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerName: 'Active Ingredient',
        hide: (environmentalAssessment === Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT) && this.logicService.isInverseModeling === false,
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.molecules,
            columnThatModifies: 'moleculePk',
            optionLabel: 'moleculeName',
            optionValue: 'moleculePk',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects),
            isRequired: true
          }
        },
        editable: false,
      },
      {
        headerName: 'Parent',
        hide: (environmentalAssessment !== Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT) || this.logicService.isInverseModeling === true,
        field: 'compoundPk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'compoundName',
        },
        instance: this,
        filterable: true,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.parentDropDownOptions,
            columnThatModifies: 'compoundPk',
            optionValue: 'compoundPk',
            optionLabel: 'compoundName',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects),
            isRequired: true,
            optionsToRemove: this.logicService.metabolitesToRemove,
            fieldToRemove: 'compoundPk'
          }
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        headerComponent:'infoGridHeaderComponentComponent',
        headerComponentParams: {
          name: 'Data Set',
          infoText: Constants.MESSAGES.DATA_SET_INFO,
          popupWidth:350
        },
        field: 'description',
        minWidth: 563,
        cellRenderer: InputTextRenderer,
        cellRendererParams: (params: any) => {
          return {
            disabled: this.disableInput(isProjectOwnershipValid, params, projects),
            isRequired: true
          }
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 253,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: (params: any) => {
          return {
            filterable: false,
            optionLabel: 'description',
            optionValue: 'dataSetPk',
            options: this.logicService.datasetsToImport,
            parent: this,
            selectedValue: 0,
            placeholder: 'Import Data Set',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects),
          }
        },
        instance: this,
      },
      {
        colId: 'Referenced DataSet',
        width: 60,
        minWidth: 60,
        editable: false,
        cellRenderer: DatasetReferenceIconRenderer,
        cellRendererParams:  (params: any) => {
          let useIcon = params?.data?.originalProject ? params?.data?.originalProject !== this.logicService.projectPk ? 'pi pi-link' : undefined : undefined;
          return{
            disabled: !isProjectOwnershipValid,
            icon: useIcon,
            toolTipMessage: useIcon ? 'This is a referenced dataset' : undefined,
          }
        },
        type: 'rightAligned'
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams:{
          disabled: !isProjectOwnershipValid,
        },
        type: 'rightAligned'
      }
    ]
  }

  public getColumnsDefinitionSubstancePropertiesOPEX(isProjectOwnershipValid: boolean) {
    return [
      {
        colId: 'show',
        minWidth: 80,
        width: 80,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        headerName: 'Calculator',
        field: 'calculatorGroup',
        width: 135,
        minWidth: 135,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          options: Constants.CALCULATOR_GROUPS,
          columnThatModifies: 'calculatorGroup',
          optionLabel: 'name',
          optionValue: 'name',
          disabled: !isProjectOwnershipValid,
          isRequired: false,

        },
        editable: false,
      },
      {
        headerName: 'Active Ingredient',
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          options: this.logicService.molecules,
          columnThatModifies: 'moleculePk',
          optionLabel: 'moleculeName',
          optionValue: 'moleculePk',
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        field: 'description',
        width: 465,
        minWidth: 465,
        cellRenderer: InputTextRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 215,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: {
          filterable: false,
          optionLabel: 'description',
          optionValue: 'dataSetPk',
          options: this.logicService.datasetsToImport,
          parent: this,
          selectedValue: 0,
          placeholder: 'Import Data Set',
          disabled: !isProjectOwnershipValid,
        },
        instance: this,
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid
        },
        type: 'rightAligned'
      }
    ]
  }

  private disableInput(isProjectOwnershipValid: boolean, params: any, projects: Project[]): boolean {
    if(!isProjectOwnershipValid) return true;
    return params?.data?.newRow ? false :
    params?.data?.createdBy != null ? params?.data?.createdBy !== this.userLogicService?.profile?.displayName :
    projects.find((project) => project.projectPk === params?.data?.originalProject)?.createdBy !== this.userLogicService?.profile?.displayName;
  }
}
