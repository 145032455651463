import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, inject, DestroyRef } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { MetabolitePkAndName } from 'src/app/shared/models/echo/metabolite';
import { EEAEndpointsByCompartmentLogicService } from '../../eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Constants } from 'src/app/shared/utils/constants';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';
import { DatasetXMetabolites } from 'src/app/shared/models/echo/dataset_x_metabolites';
import Swal from 'sweetalert2';
import { Project } from 'src/app/shared/models/project';

@Component({
  selector: 'app-eea-endpoints-select-compound',
  templateUrl: './eea-endpoints-select-compound.component.html',
  styleUrls: ['./eea-endpoints-select-compound.component.css']
})
export class EEAEndpointsSelectCompoundComponent implements OnInit {
  @Input() projectPk: number;
  @Input() moleculePk: number;
  @Input() compartment!: string;
  @Input() metabolitesOptions!: MetabolitePkAndName[];
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() isDaugtherSelected: boolean = false;
  
  @Input() dataSet: DataSet | null = null;
  
  @Output() selectedMetabolitePk = new EventEmitter<number>();
  @Output() selectedActiveIngredientPk = new EventEmitter<number>();
  public metaboliteSelected: boolean;
  public activeButtonColor: string;
  public metaboliteButtonColor: string;
  public selectedMetabolitePkValue?: number;
  private unSelectedButtonColor: string;
  private selectedButtonColor: string;
  public excludeMetaboliteFromAI: boolean | undefined;
  public buttonLabel: string;
  public daugtherOptionSelected: boolean | undefined;
  public granddaugtherOptionSelected: boolean | undefined;
  public showDaughter: boolean | undefined;
  public showGranddaughter: boolean | undefined;
  destroyRef = inject(DestroyRef);

  constructor(private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService,
    private readonly endpointDataSetLogicService: EndpointDatasetLogicService,
  ){
    this.projectPk = 0;
    this.moleculePk = 0;
    this.metaboliteSelected = false;
    this.unSelectedButtonColor = 'secondary';
    this.selectedButtonColor = 'warning';
    this.activeButtonColor =  this.selectedButtonColor;
    this.metaboliteButtonColor =  this.unSelectedButtonColor;
    this.selectedMetabolitePkValue = 0;
    this.excludeMetaboliteFromAI = undefined;
    this.buttonLabel = 'Active Ingredient';
    this.daugtherOptionSelected = undefined;
    this.granddaugtherOptionSelected = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const metabolitesOptions = changes['metabolitesOptions'];
    if (metabolitesOptions && metabolitesOptions.previousValue !== metabolitesOptions.currentValue) {
      this.metaboliteSelected = false;
      this.selectedMetabolitePkValue = 0;
    }
  }

  ngOnInit(){    
    this.endpointByCompartmentLogicService.excludeMetabolite$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.excludeMetaboliteFromAI = value;
      }
    });
    this.endpointByCompartmentLogicService.isDaughter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.daugtherOptionSelected = value;
      }
    });
    this.endpointByCompartmentLogicService.isGranddaughter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.granddaugtherOptionSelected = value;
      }
    }); 
    this.endpointByCompartmentLogicService.showDaughter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.showDaughter = value;
      }
    });
    this.endpointByCompartmentLogicService.showGranddaughter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.showGranddaughter = value;
      }
    });    
    this.buttonLabel = this.getButtonLabel();
    this.emitCompoundSelection();
  }

  public onActiveIngredientClick() : void{
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(undefined);
    this.metaboliteSelected = false;
    this.selectedMetabolitePkValue = 0;
    this.selectedActiveIngredientPk.emit(this.moleculePk);
    this.activeButtonColor = this.selectedButtonColor;
    this.metaboliteButtonColor = this.unSelectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public onMetaboliteClick() : void{
    this.selectedActiveIngredientPk.emit(undefined);
    this.metaboliteSelected = true;
    this.selectedMetabolitePkValue = 0;
    this.activeButtonColor = this.unSelectedButtonColor;
    this.metaboliteButtonColor = this.selectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public getSelectedMetabolite(dropdownEvent: DropdownChangeEvent): void{
    this.selectedMetabolitePkValue = dropdownEvent.value.metabolitePk;
    this.selectedActiveIngredientPk.emit(undefined);
    this.selectedMetabolitePk.emit(dropdownEvent.value.metabolitePk);
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public onChangeExcludeMetabolite(checkBoxEvent: CheckboxChangeEvent): void{
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(checkBoxEvent.checked);
  }

  public onChangeSelectedDaugther(checkBoxEvent: CheckboxChangeEvent): void{
    if(checkBoxEvent.checked == false){
      Swal.fire({
        text: 'If you unselect the Daughter, the Granddaughter will be unselected too. Do you want to proceed?',
        showCancelButton: true,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        icon: 'warning'
      }).then((result) => {
        if (result.value) {
          this.endpointByCompartmentLogicService.isDaugtherSelected(checkBoxEvent.checked);
        } else {
          this.daugtherOptionSelected = true;
        }
      });
    } else {
      this.endpointByCompartmentLogicService.isDaugtherSelected(checkBoxEvent.checked);
    }
  }

  public onChangeSelectedGranddaugther(checkBoxEvent: CheckboxChangeEvent){
    this.endpointByCompartmentLogicService.isGranddaugtherSelected(checkBoxEvent.checked);   
  }

  private emitCompoundSelection(): void{
    if(this.dataSet){
      this.dataSet.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ? 
      this.selectedActiveIngredientPk.emit(this.dataSet.moleculePk) :
      this.selectedMetabolitePk.emit(this.dataSet.metabolitePk);
    }else{
      this.selectedActiveIngredientPk.emit(this.moleculePk);
    }
  }

  private getButtonLabel(): string{
   return (this.dataSet != null) ? !this.endpointDataSetLogicService.isInverseModeling ? 'Parent' : 'Active Ingredient' : 'Active Ingredient';
  }
}
