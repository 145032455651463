import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EEAGapGroundWaterColdef } from './eea-gap-ground-water.coldef';
import { EEAGapApiService } from '../eea-gap.api.service';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { EeaGapNonCoreCompartmentComponent } from '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component';
import { Constants } from 'src/app/shared/utils/constants';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { catchError, forkJoin } from 'rxjs';
import { ApplicationDateGroundWater, ApplicationDateSelection } from 'src/app/shared/models/application-date';
import { CropList } from 'src/app/shared/models/crop-list';
import { ApplicationSchemeXApplicationWindow } from 'src/app/shared/models/application-scheme';
import { Utils } from 'src/app/shared/utils/utils';
import { RowNode } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-eea-gap-ground-water',
  templateUrl: '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component.html',
  styleUrls: ['./eea-gap-ground-water.component.css']
})

export class EEAGapGroundWaterComponent extends EeaGapNonCoreCompartmentComponent implements OnInit, OnChanges {
  override compartment: string = Constants.COMPARTMENTS.GROUND_WATER;
  applicationDates: ApplicationDateGroundWater[] = [];
  override applicationDatesByCrop: ApplicationDateGroundWater[] = [];
  @Input() override isProjectOwnershipValid: boolean = false;


  constructor(
    private gapGWApiService: EEAGapApiService,
    private gapGroundWatereColdef: EEAGapGroundWaterColdef,
    private gapGWLogicService: EEAGapLogicService,
    EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private applicationDateGWApiService: ApplicationDateApiService,
    datepipe: DatePipe,
    gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
    super(gapGWApiService, gapGWLogicService, EEAGAPMenuLogicService, applicationDateGWApiService, datepipe, gapApplicationSchemeLogicService);
  }

  override fillInitValues(changes: SimpleChanges): void {
    let promises = [
      this.gapGWLogicService.getCompartments(),
      this.gapGWLogicService.getRegionCountry(changes['selectedProject'].currentValue),
      this.gapGWLogicService.getCropInterceptions(),
      this.gapGWLogicService.getAllCropList(),
      this.gapGWLogicService.getCropListMatchings(),
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.geographiesList = results[1];
        this.cropList = results[3];
        this.cropListMatchings = results[4];
        this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCoreCompartment());
      })
  }

  override getColDefFilteredBySelectedModels() {
    if (!this.selectedProject) return;
    let models = this.gapGWLogicService.getModelsByCompartment(this.compartment, this.selectedProject);
    let columnsByModel = this.gapGWLogicService.getDisplayedColumnsByModel(models, this.selectedProject?.geography!);

    const colDefParams = {
      bbch: this.gapGWLogicService.bbch,
      numerOfApplications: this.gapGWLogicService.numerOfApplications,
      applicationMethods: Constants.APPLICATION_METHOD_VALUES_GROUNDWATER.map(key => ({ value: key })),
      geographiesList: this.geographiesList,
      cropList: (this.selectedProject?.geography == Constants.CROP_GEOGRAPHIES.USA) ? this.cropList?.filter((x: CropList) => x.Geography === Constants.CROP_GEOGRAPHIES.USA) : this.cropList,
      geographySelected: this.selectedProject?.geography,
      cropListMatching: this.cropListMatchings,
    };

    const columnsDefinition = this.isInverseModeling
      ? this.gapGroundWatereColdef.getInverseColumnsDefinition(colDefParams, this.selectedProject, this.isProjectOwnershipValid)
      : this.gapGroundWatereColdef.getColumnsDefinition(colDefParams, this.isProjectOwnershipValid);


    let columnsToShow = [...Constants.GAP_CORE_FIELDS, ...new Set(columnsByModel), ...this.fixedColumns];
    if (this.isInverseModeling) columnsToShow = [...columnsToShow, ...Constants.GAP_CORE_INVERSE_MODELING_FIELDS];
    return this.gapGWLogicService.getFilteredColumns(columnsToShow, columnsDefinition);
  }

  override getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      const detailsColumnDef = this.gapGroundWatereColdef.configureDetailGrid(this.isProjectOwnershipValid);
      forkJoin({
        applicationSchemes: this.gapGWApiService.getApplicationSchemesByProjectAndCompartment(projectPk, compartmentPk),
      })
        .pipe(
          catchError((error) => {
            console.error(error);
            this.loading = false;
            return [];
          })
        )
        .subscribe({
          next: ({ applicationSchemes }) => {
            if (!this.selectedProject) return;
            this.gapGWLogicService.addActiveIngredientsRatesToGrid(applicationSchemes, this.getColDefFilteredBySelectedModels(), this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, this.compartment).then(() => {
              this.gapGWLogicService.addActiveIngredientsRatesToDetailGrid(this.gapGWLogicService.rowData, detailsColumnDef, this.isInverseModeling, this.selectedProject!, this.isProjectOwnershipValid, this.compartment);
              this.rowData = this.gapGWLogicService.rowData;
              this.rowData.forEach((x: any) => {
                let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
                let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
                x.selectedInterceptionModel = interceptionModel;
                if (x.interception === null) {
                  this.gapGWLogicService.setCropInterceptionByCropAndBBCH(x, this.compartment);
                }
              });

              this.columsDef = this.gapGWLogicService.columsDef;
              this.detailsColumsDef = this.gapGWLogicService.detailsColumnDef;
              this.refreshSelectedValuesTimeout();
              this.setLoadingState(false);
            });
          },
        });
    }
  }

  override onClickViewButton(event: any) {
    this.selectedApplicationWindowRow = event.rowNode;
    let gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === this.selectedApplicationWindowRow?.data?.cropAppDatePk)?.CropName!;
    let cropWithoutDates = [...Constants.GAP_CROPS_WITHOUT_APP_DATES_GROUNDWATER].map(v => v.toLowerCase());
    if (this.selectedApplicationWindowRow?.data?.cropAppDatePk && this.selectedApplicationWindowRow?.data?.bbchEarliest && this.selectedApplicationWindowRow?.data?.cropInterceptionPk) {
      let promises = [
        this.gapGWLogicService.getApplicationDatesGroundWaterByCropListPkAndBBCH(this.selectedApplicationWindowRow?.data?.cropAppDatePk, Number(this.selectedApplicationWindowRow?.data?.bbchEarliest), this.selectedApplicationWindowRow?.data?.cropInterceptionPk),
      ]
      Promise.all(promises)
        .then((results) => {
          results[0] = this.filterResultsByMacro(results[0]);
          this.applicationDates = results[0];
          if (gapCropName && cropWithoutDates.includes(gapCropName.toLowerCase() ?? '')) {
            let cropScenariosWithoutDates = Object.entries({ ...Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES_GROUNDWATER }) as [string, any];
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateGroundWater) => cropScenariosWithoutDates.find(([key, value,]) => (key.toLowerCase() === gapCropName.toLowerCase()) && value.includes(x.location)));
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateGroundWater) => {
              let value = event?.data?.applicationSchemeXApplicationWindow?.find((x: any) => x.applicationDateGroundWaterPk === appDate.applicationDateGroundWaterPk);
              if (value) {
                appDate.recAppDate = value.firstDate;
              }
              else {
                appDate.recAppDate = this.gapGWLogicService.getNonLeapDate(Constants.DEFAULT_GW_YEAR);
              }
            });
          }
          else {
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateGroundWater) => x.requestedBBCH != null || x.allocatedBBCH != null);;
            this.selectedApplicationWindowRow!.data.cropWithoutAppDates = false;
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateGroundWater) => {
              let value = event?.data?.applicationSchemeXApplicationWindow?.find((x: any) => x.applicationDateGroundWaterPk === appDate.applicationDateGroundWaterPk);
              if (value) {
                appDate.recAppDate = value.firstDate;
              } else {
                const currentYear = new Date(Constants.DEFAULT_GW_YEAR, 0, 1).getFullYear();
                appDate.recAppDate = appDate?.recAppDate!.replace(/(\d{4})/, currentYear.toString());
              }
            });
          }
        });
    }
    else {
      this.applicationDates = [];
      this.applicationDatesByCrop = []
    }
    this.applicationDialog.showDialog(event.rowNode, this.compartment);
  }

  filterResultsByMacro(results: ApplicationDateGroundWater[]) {
    let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
    var onlyMacro = selectedModels.includes(Constants.MODELS.MACRO_GW) && !selectedModels.includes(Constants.MODELS.PELMO) && !selectedModels.includes(Constants.MODELS.PEARL);
    return onlyMacro ? results.filter((x: any) => x?.location === Constants.GW_LOCATIONS.CHATEAUDUN) : results;
  }

  override onSaveApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let currentDates = selectedAppDates.parentRowNode.data.applicationSchemeXApplicationWindow;
    if (currentDates?.length !== selectedAppDates.selectedRows.length) {
      this.createTransactionForApplicationDates(selectedAppDates);
    } else {
      for (const selectedDate of selectedAppDates.selectedRows) {
        const existAppDate = currentDates.some((currentDate: any) =>
          currentDate.applicationDateGroundWaterPk === selectedDate.data.applicationDateGroundWaterPk &&
          currentDate.firstDate === selectedDate.data.recAppDate &&
          currentDate.min === selectedDate.data.min &&
          currentDate.max === selectedDate.data.max &&
          currentDate.increment === selectedDate.data.increment
        );
        if (!existAppDate) {
          this.createTransactionForApplicationDates(selectedAppDates);
          break;
        }
      }
    }
  }

  override getScenariosWithoutCropListPk() {
    let gapCrop = this.selectedApplicationWindowRow?.data?.cropAppDatePk;
    if (gapCrop) {
      let gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === gapCrop)?.CropName;
      if (gapCropName && Constants.GAP_CROPS_WITHOUT_APP_DATES_GROUNDWATER.includes(gapCropName ?? '')) {
        let gapCropScenarios: string[] = Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES_GROUNDWATER[gapCropName];
        let scenarios = this.applicationDates.filter((x: ApplicationDateGroundWater) => x.cropListPk == gapCrop);
        this.selectedApplicationWindowRow!.data.cropWithoutAppDates = Constants.GAP_CROPS_WITHOUT_APP_DATES_GROUNDWATER.includes(gapCropName ?? '');
        this.applicationDatesByCrop = scenarios;
      }
    }
  }

  override onFirstDataRendered(params: any): void {
    if (params?.type === Constants.GRID_EVENTS.FIRST_DATA_RENDERED) {
      this.applicationDatesByCrop = [];
      this.grid.gridApi.forEachNode((node: RowNode) => {
        if (node?.data?.coreApplicationSchemePk) {
          node!.data.reloadCore = this.validateCoreCropPK(node, this.compartment, this.selectedProject?.geography!, this.cropListMatchings);
        }
        if (node?.data?.applicationSchemeXApplicationWindow?.length == 0) {
          this.saveAppWindowsAfterLoad(node);
        }
        if (node?.data?.numberOfApplications === 1) {
          node!.data.applicationInterval = 1;
          node!.data.hasVariableRates = false;
        }
        //TODO: Luis Miranda - This code lines must be uncommented when variable rates work.
        // if (node?.data?.hasVariableRates) {
        //   node!.data.applicationInterval = null;
        //   Object.entries(node?.data).forEach(([key, value]) => {
        //     if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE)) {
        //       node!.data[key] = null;
        //     }
        //   });
        // }

        // if (node?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
        //   node!.data.applicationInterval = null;
        //   node!.data?.children?.map((child: any) => {
        //     child.applicationInterval = null;
        //   });
        // }
        // node!.data.children?.map((child: any) => {
        //   child.dateType = node?.data?.dateType;
        // });
      });
      this.grid.gridApi.redrawRows();
      this.grid.gridApi.forEachNode((node: RowNode) => { node!.data.reloadCore = false; });
    }
  }

  saveAppWindowsAfterLoad(node: RowNode): void {
    let gapCropPk: number | undefined = node.data?.cropAppDatePk;
    let cropInterceptionPk: number | undefined = node.data?.cropInterceptionPk;
    let gapCropName: string = "";

    if (Utils.isEmptyValue(gapCropPk)) {
      gapCropPk = this.getCropValueMatchings(node);
    }
    gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === gapCropPk)?.CropName!;

    if (gapCropPk && node.data?.bbchEarliest && cropInterceptionPk) {
      let promises = [
        this.gapGWLogicService.getApplicationDatesGroundWaterByCropListPkAndBBCH(gapCropPk, Number(node.data?.bbchEarliest), cropInterceptionPk),
      ]
      Promise.all(promises)
        .then((results) => {
          let appDates: any[] = [];
          this.applicationDates = results[0];
          let cropWithoutDates = [...Constants.GAP_CROPS_WITHOUT_APP_DATES_GROUNDWATER].map(v => v.toLowerCase());
          if (gapCropName && cropWithoutDates.includes(gapCropName.toLowerCase() ?? '')) {
            let cropScenariosWithoutDates = Object.entries({ ...Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES_GROUNDWATER }) as [string, any];
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateGroundWater) => cropScenariosWithoutDates.find(([key, value,]) => (key.toLowerCase() === gapCropName.toLowerCase()) && value.includes(x.location)));
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateGroundWater) => {
              appDate.recAppDate = this.datepipe.transform(this.gapGWLogicService.getNonLeapDate(Constants.DEFAULT_GW_YEAR), Constants.SERVICE_DATE_FORMAT)!;
              appDates.push({ data: appDate });
            });
          }
          else {
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateGroundWater) => x.recAppDate != null);
            this.applicationDatesByCrop = this.filterResultsByMacro(this.applicationDatesByCrop);
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateGroundWater) => {
              const currentYear = new Date(Constants.DEFAULT_GW_YEAR, 0, 1).getFullYear();
              appDate.recAppDate = appDate?.recAppDate!.replace(/(\d{4})/, currentYear.toString());
              appDates.push({ data: appDate });
            });
          }

          if (appDates.length > 0) {
            this.onSaveApplicationDates({ selectedRows: appDates, parentRowNode: node });
          }
        });
    }
    else {
      this.applicationDates = [];
      this.applicationDatesByCrop = []
      this.createTransactionForApplicationDates({ selectedRows: [], parentRowNode: node });
    }
  }

  override createTransactionForApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let dates: ApplicationSchemeXApplicationWindow[] = [];
    if (selectedAppDates.selectedRows.length == 0) {
      dates = [];
    }
    else {
      dates = selectedAppDates.selectedRows.map((x: any) => {
        let object: ApplicationSchemeXApplicationWindow = {
          applicationDateGroundWaterPk: x.data.applicationDateGroundWaterPk,
          recAppDate: this.gapGWLogicService.transformLeapDate(x.data.recAppDate, Constants.DEFAULT_GW_YEAR),
          firstDate: this.gapGWLogicService.transformLeapDate(x.data.recAppDate, Constants.DEFAULT_GW_YEAR),
          min: x.data.min,
          max: x.data.max,
          increment: x.data.increment,
        }
        return object;
      })
    }
    let parentRowNode = this.grid.gridApi.getRowNode(selectedAppDates.parentRowNode.id);
    let oldvalue = parentRowNode.data.applicationSchemeXApplicationWindow;
    parentRowNode.data.applicationSchemeXApplicationWindow = dates;
    this.grid.CreateTransaction(parentRowNode.id, parentRowNode.id, oldvalue, parentRowNode.data);
  }

  override onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event?.field === Constants.GAP_FIELD_NAMES.DATE_TYPE) {
      event.row?.children?.map((child: any) => {
        child.dateType = event?.value;
      });
      if (event?.value === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
        event.row.cropEvent = null;
        event.row.daysSince = null;
        // event.row.applicationInterval = null; //TODO: Luis Miranda - This code line must be uncommented when variable rates work.
        //TODO: Luis Miranda - This code lines must be uncommented when variable rates work.
        // event.row?.children?.map((child: any) => {
        //   child.applicationInterval = null;
        // });
      }
      this.grid.gridApi.redrawRows();
    }
    else if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH) {
      let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
      let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
      event.row.selectedInterceptionModel = interceptionModel;
      if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK) {
        event.row.cropInterceptionPk = event.value;
      }

      this.gapGWLogicService.setCropInterceptionByCropAndBBCH(event.row, this.compartment);

      this.grid.gridApi.refreshCells();
    }

    if (event.field === Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH) {
      this.saveAppWindowsAfterLoad(this.grid.gridApi.getRowNode(event.id));
    }
    if (event.field === Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER) {
      if (event.value === 1) {
        event.row.applicationInterval = 1;
        //event.row.hasVariableRates = false; //TODO: Luis Miranda - This code line must be uncommented when variable rates work.
      }
      else {
        //event.row.hasVariableRates = true; //TODO: Luis Miranda - This code line must be uncommented when variable rates work.
      }
      this.gapGWLogicService.setInnerGridValues(event, this.grid);
      this.grid.gridApi.redrawRows();
    }
    if (event?.field === Constants.GAP_FIELD_NAMES.APPLICATION_METHOD) {
      event.row.soilDepth = [Constants.APP_METHOD_VALUES_GROUNDWATER.SOIL_SURFACE, Constants.APP_METHOD_VALUES_GROUNDWATER.CROP_CANOPY].includes(event.value) ? 0 : null;
      this.grid.gridApi.redrawRows();
    }

    if (event.field === Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES) {
      if (event.value) {
        event.row.applicationInterval = null;
        Object.entries(event.row).forEach(([key, value]) => {
          if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE)) {
            event.row[key] = null;
          }
        });
      }
      else {
        event.row.isExpanded = false;
        this.gapGWLogicService.toggleDetailGrid(event, this.grid);
      }
      this.grid.gridApi.redrawRows();
    }
  }

}
