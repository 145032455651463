<div class="formgrid grid mr-2">
    <div class="col-5 font-bold">
        <app-input-text [disabled]="!selectedProject || !isProjectOwnershipValid" [label]="'Project Name'" [useIcon]="true" [useTitle]="true" [id]="'nameInput'"
            [model]="selectedProject?.name" [isValid]="isProjectNameValid" [isValueDuplicated]="isProjectNameDuplicated" [isValueDuplicatedMessage]="'This project name already exists'" [isRequired]="true"
            (onChangeEvent)="projectNameChanged($event)"></app-input-text>            
    </div>
    <div class="col-1 font-bold mt-5">
        <app-button [disabled]="!selectedProject || !isProjectOwnershipValid || !isProjectNameValid" [label]="'Import'" [size]="'small'" (onClickEvent)="copyProject()"
            [severity]="'warning'" [outlined]="true"></app-button>
    </div>
    <div class="col-6 mt-2 font-bold">
        <app-input-textarea [disabled]="!selectedProject || !isProjectOwnershipValid" [label]="'Project Description'" [useTitle]="true" class="w-10"
            [id]="'descriptionInput'" [autoResize]="false" [rows]="1" [model]="selectedProject?.description"
            (onChangeEvent)="projectDescriptionChanged($event)"></app-input-textarea>
    </div>
</div>

<app-eea-copy-project-dialog #appDialog [isEEA]="isEEA" (onClickSaveButtonEvent)="onSaveDataProject($event)"> </app-eea-copy-project-dialog>

<div class="formgrid grid mt-2 mr-2">
    <div class="col-3 font-bold">
        <app-auto-complete 
            [hardDisabled]="!selectedProject || !isProjectOwnershipValid"
            [isValid]="isFormulationValid"
            [label]="'Formulation'"
            [id]="'nameInput'"
            [placeholder]="'Start typing to search'"
            [isRequired]="true"
            [forceSelection]="true"
            [showClear]="true"
            [optionLabel]="'tradeName'" 
            [suggestions]="filteredFormulations" 
            [model]="selectedFormulation"
            (queryFilterEvent)="onQueryFilterEvent($event)" 
            (selectionChangedEvent)="formulationSelectionChanged($event)"
            (onClearEvent)="formulationOnClear($event)"
            (onDropdownClickEvent)="formulationDropdownClicked($event)" >
        </app-auto-complete>
    </div>
    <div class="col-3 font-bold">
        <app-dropdown [hardDisabled]="!selectedProject || !isProjectOwnershipValid" [options]="geographies" [label]="'Geography'" [useTitle]="true"
            class="w-10" [isValid]="isGeographyValid" [isRequired]="true" [id]="'nameInput'" [showClear]="true"
            [placeholder]="'Select a Geography'" [optionLabel]="'name'" [loading]="loadingGeographies"
            [model]="selectedGeography" (onChangeEvent)="geographySelectionChanged($event)"></app-dropdown>
    </div>

    <div class="col-6 font-bold" style="width: 40%;">
        <app-input-text [label]="'Created by'" [useTitle]="true" class="w-10" [id]="'descriptionInput'"
            [disabled]="true" [model]="createdBy"></app-input-text>
    </div>
    <div class="col-1 font-bold" style="padding-top: 15px;">
        <app-button [disabled]="isArchiveButtonDisabled" [label]="'Archive Project'" [size]="'small'" [severity]="'warning'" [outlined]="true" 
        (onClickEvent)="archiveProject()"></app-button>
    </div>
</div>

<div *ngIf="isHHRA" class="formgrid grid mt-2 mr-2">
    <div class="col-3 font-bold">
        <app-input-text [label]="'Formulation Type'" [useTitle]="true" class="w-10" [id]="'formulationTypeInput'"
            [disabled]="true" [model]="selectedProject?.formulationType"></app-input-text>
    </div>
    <div class="col-3 font-bold" *ngIf="showOPEXFormulationType">
        <app-input-text [label]="'OPEX Formulation Type'" [useTitle]="true" class="w-10" [id]="'OPEXFormulationTypeInput'"
            [disabled]="true"  [model]="selectedProject?.OPEXFormulationType"></app-input-text>
    </div>
    <div class="col-6 font-bold">
    </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>