import { Injectable } from '@angular/core';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { DeleteButtonRenderer } from 'src/app/shared/renderers/delete-button/delete-button.component';
import { InputSearchRenderer } from 'src/app/shared/renderers/input-search/input-search.component';
import { NumericEditorRenderer } from 'src/app/shared/renderers/numeric-editor/numeric-editor.component';
import { Utils } from 'src/app/shared/utils/utils';
import { CropDropdownListRenderer } from '../shared/renderers/crop-dropdown-list/crop-dropdown-list.component';
import { Constants } from 'src/app/shared/utils/constants';
import { ViewButtonRenderer } from 'src/app/shared/renderers/view-button/view-button.component';
import { CalendarRenderer } from 'src/app/shared/renderers/calendar/calendar.component';
import { ColGroupDef, RowNode } from 'ag-grid-community';
import { Project } from 'src/app/shared/models/project';
import { ButtonExpandGridRenderer } from 'src/app/shared/renderers/button-expand-grid/button-expand-grid.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { ListboxRenderer } from 'src/app/shared/renderers/listbox/listbox.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { InputTextJulianDateComponent } from './input-text-julian-date/input-text-julian-date.component';

@Injectable({
    providedIn: 'root'
})
export class EEAGapGroundWaterColdef {

    constructor() { }

    public getColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        headerComponent:'infoGridHeaderComponentComponent',
                        headerComponentParams: {
                          name: 'Application Scheme',
                          infoText: Constants.MESSAGES.APP_SCHEME_INFO,
                          popupWidth:415
                        },    
                        field: 'name',
                        type: 'default',
                        resizable: true,
                        pinned: 'left',
                        width: 300,
                        hide: false,
                        filter: 'agSetColumnFilter',
                        wrapText: true,
                        validationType: 'string',
                        editable: false,
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRenderer: InputTextRenderer,
                        cellRendererParams: {
                          disabled: !isProjectOwnershipValid,
                          isRequired: true
                        },
                    },
                    {
                        headerName: 'Crop (Interception)',
                        field: 'cropInterceptionPk',
                        type: 'select',
                        resizable: true,
                        width: 270,
                        editable: false,
                        hide: false,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                        validationType: 'string'
                    },
                    {
                        headerName: 'Crop (AppDate)',
                        field: 'cropAppDatePk',
                        type: 'select',
                        resizable: true, width: 200,
                        editable: false,
                        hide: false,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.APP_DATE,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropAppDatePk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                        validationType: 'string'
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        field: 'geographies',
                        type: 'select',
                        filter: 'agSetColumnFilter',
                        // hide: ((JSON.stringify(options.selectedModels) === JSON.stringify(new Array(Constants.MODELS.UK)))) || (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA), // TODO: Luis Miranda - uncomment when the new Geographies component is implemented
                        hide: true,
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.geographiesList, 'key', 'name')
                            },
                        },
                        cellRenderer: ListboxRenderer,
                        cellRendererParams: {
                            options: options.geographiesList,
                            optionValue: 'key',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: false,
                            validateModel: false,
                            compartment: Constants.COMPARTMENTS.GROUND_WATER,

                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 150,
                        editable: false,
                    },
                    {
                        headerName: 'Number of Applications',
                        field: 'numberOfApplications',
                        wrapText: true,
                        type: 'select',
                        resizable: true, width: 120,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numerOfApplications,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'numberOfApplications',
                                height: '300px',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        hide: false,
                        validationType: 'string'
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Variable rates or intervals?',
                        field: 'hasVariableRates',
                        width: 115,
                        wrapText: true,
                        resizable: true, editable: false,
                        hide: true, //TODO: Geremy Rivera - We need to change run projects(options.geographySelected != Constants.CROP_GEOGRAPHIES.EUROPE_UNION),
                        type: 'select',
                        suppressSizeToFit: true,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            valueFormatter: (params: any) => {
                                return params.value ? Constants.AFFIRMATIVE_VALUES.YES : !Utils.isEmptyValue(params.value) ? Constants.AFFIRMATIVE_VALUES.NO : '(Blanks)';
                            }
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: [{ value: true, key: Constants.AFFIRMATIVE_VALUES.YES }, { value: false, key: Constants.AFFIRMATIVE_VALUES.NO }],
                                columnThatModifies: 'hasVariableRates',
                                disabled: params.data.numberOfApplications == 1 ? false : true,
                                optionLabel: 'key',
                                optionValue: 'value',
                                parent: this,
                            };
                        },
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    },
                    {
                        headerName: '',
                        field: 'expandButton',
                        cellRenderer: ButtonExpandGridRenderer,
                        width: 50,
                        resizable: true, suppressSizeToFit: true,
                        hide: true, //TODO: Geremy Rivera - We need to change run projects (options.geographySelected != Constants.CROP_GEOGRAPHIES.EUROPE_UNION),
                        type: 'default',
                        autoHeight: true,
                        editable: false,
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1 || params.data.hasVariableRates == false) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Interval (days)',
                        wrapText: true,
                        field: 'applicationInterval',
                        type: 'default',
                        resizable: true,
                        width: 120,
                        filter: 'agSetColumnfilter',
                        instance: this,
                        hide: false,
                        validationType: 'number',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                disabled: !isProjectOwnershipValid || params.data.numberOfApplications === 1,
                            }
                        },
                        //TODO: Luis Miranda - This method must be removed when variable rates work.
                        // //TODO: Luis Miranda - This method must be uncommented when variable rates work.
                        // cellStyle: (params: any) => {
                        //     if (params.data.numberOfApplications === 1 || params.data.hasVariableRates || params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                        //         return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                        //     }
                        //     return null;
                        // },
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        field: 'bbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable: true, editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'bbchEarliest',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        hide: false,
                        validationType: 'string'
                    },
                    {
                        headerName: 'Interception (%)',
                        wrapText: true,
                        field: 'cropInterception',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 110,
                        resizable: true, hide: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        validationType: 'number',
                        editable: false,
                    },
                    {
                        headerName: 'Date Type',
                        field: 'dateType',
                        type: 'select',
                        width: 150,
                        resizable: true, editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: [{ value: Constants.DATE_TYPE_VALUES.RELATIVE }, { value: Constants.DATE_TYPE_VALUES.ABSOLUTE }],
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'dateType',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        hide: false,
                        validationType: 'string'
                    },
                    {
                        headerName: 'Application Date',
                        field: 'appWindow',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 150,
                        resizable: true, editable: false,
                        hide: options.geographySelected !== Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                        cellRenderer: ViewButtonRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,

                            };

                        },
                        cellStyle: (params: any) => {
                            if (params.data.dateType === Constants.DATE_TYPE_VALUES.RELATIVE) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        }
                    },
                    {
                        headerName: 'Crop Event',
                        field: 'cropEvent',
                        type: 'select',
                        resizable: true,
                        width: 150,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: [{ value: 'Emergence' }, { value: 'Harvest' }, { value: 'Maturity' }],
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'cropEvent',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        hide: false,
                        validationType: 'string',
                        cellStyle: (params: any) => {
                            if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        }
                    },
                    {
                        headerName: 'First Application Date',
                        field: 'ukApplicationDate',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 150,
                        resizable: true,
                        editable: false,
                        hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            const firstDate = params?.data?.applicationSchemeXApplicationWindow?.length > 0;
                            if (firstDate) {
                                params.data.ukApplicationDate = firstDate
                            }
                            return {
                                instance: this,
                                columnThatModifies: 'ukApplicationDate',
                                highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        validationType: 'string',
                    },
                    {
                        headerName: 'Days Since',
                        field: 'daysSince',
                        width: 100,
                        resizable: true, type: 'default',
                        filter: 'agSetColumnFilter',
                        hide: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        cellStyle: (params: any) => {
                            if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                        validationType: 'numeric',
                        editable: false,
                    },
                    {
                        headerName: 'Application Method',
                        field: 'applicationMethod',
                        type: 'select',
                        resizable: true, width: 200,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.applicationMethods,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'applicationMethod',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        hide: false,
                        validationType: 'string'
                    },
                    {
                        headerName: 'Soil Depth (cm)',
                        field: 'soilDepth',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable: true, wrapText: true,
                        width: 110,
                        validationType: 'number',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        cellStyle: (params: any) => {
                            if ([Constants.APP_METHOD_VALUES_GROUNDWATER.SOIL_SURFACE, Constants.APP_METHOD_VALUES_GROUNDWATER.CROP_CANOPY].includes(params.data.applicationMethod)) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        }
                    },
                    {
                        headerName: 'Repeat Interval for Application Events',
                        field: 'repeatInterval',
                        wrapText: true,
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        width: 145,
                        validationType: 'number',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid
                        },
                    }
                ]
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }
        ];
    }

    public getApplicationColumnsDefinition(parentRowNode?: RowNode, isProjectOwnershipValid?: boolean): any {
        return [
            {
                headerName: 'Location',
                headerCheckboxSelection: isProjectOwnershipValid,
                checkboxSelection: isProjectOwnershipValid,
                field: 'location',
                editable: false,
                flex: 1,
                sort: "asc",
                type: 'default',
                wrapText: true,
                resizable: true,
                hide: false,
                filter: 'agSetColumnFilter',
                cellEditor: 'agTextCellEditor',
                cellRenderer: (params: any) => {
                    const node = params.node;
                    if (parentRowNode) {
                        const isSelected = node.data && parentRowNode.data.applicationSchemeXApplicationWindow?.some((x: any) => node.data.applicationDateGroundWaterPk === x.applicationDateGroundWaterPk);
                        if (isSelected) {
                            node.setSelected(true);
                        }
                    }
                    return node.data.location;
                },
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                    };
                },
            },
            {
                headerName: 'Application Date',
                field: 'recAppDate',
                type: 'default',
                filter: 'agSetColumnFilter',
                flex: 1,
                wrapText: true,
                resizable: true,
                editable: false,
                hide: false,
                validationType: 'string',
                cellRenderer: CalendarRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'recAppDate',
                        highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                        disabled: !isProjectOwnershipValid,
                    };
                },
            },
            {
                headerName: 'Julian Date',
                field: 'julian',
                type: 'default',
                filter: 'agSetColumnFilter',
                flex: 1,
                wrapText: true,
                resizable: true,
                editable: false,
                hide: false,
                validationType: 'string',
                cellRenderer: InputTextJulianDateComponent,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,                                                
                        disabled: !isProjectOwnershipValid,
                        value: '1'
                    };
                },
            }
        ]
    }

    public getInverseColumnsDefinition(options: any, { useNumberOfApplications, useIntervalBetweenApplications, useRateGHA, useBBCH }: Project, isProjectOwnershipValid: boolean): any[] {

        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        children: [{
                            headerName: '',
                            field: 'name',
                            type: 'default',
                            pinned: 'left',
                            resizable: true,
                            width: 300,
                            filter: 'agSetColumnFilter',
                            editable: false,
                            cellRenderer: InputTextRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                                isRequired: true
                            },
                            cellEditorParams: {
                                maxLength: 200,
                            },
                        }],
                    },
                    {

                        headerName: 'Crop (Interception)',
                        children: [{
                            headerName: '',
                            field: 'cropInterceptionPk',
                            type: 'select',
                            resizable: true,
                            wrapText: true,
                            width: 270,
                            editable: false,
                            hide: false,
                            filter: 'agSetColumnfilter',
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                                },
                            },
                            cellRenderer: CropDropdownListRenderer,
                            cellRendererParams: {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                disabled: !isProjectOwnershipValid,
                            }
                        }]

                    },
                    {
                        headerName: '',
                        children: [{
                            headerName: 'Crop (AppDate)',
                            field: 'cropAppDatePk',
                            type: 'select',
                            wrapText: true,
                            resizable: true, width: 200,
                            editable: false,
                            hide: false,
                            filter: 'agSetColumnfilter',
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                                },
                            },
                            cellRenderer: CropDropdownListRenderer,
                            cellRendererParams: {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.APP_DATE,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropAppDatePk',
                                disabled: !isProjectOwnershipValid,
                            }
                        }]

                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        children: [{
                            headerName: '',
                            field: 'countryPk',
                            type: 'select',
                            resizable: true, width: 150,
                            editable: false,
                            hide: (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA),
                            filter: 'agSetColumnfilter',
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.cropList, 'key', 'name')
                                },
                            },
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: {
                                options: options.geographiesList,
                                optionValue: 'key',
                                optionLabel: 'name',
                                columnThatModifies: 'countryPk',
                                disabled: !isProjectOwnershipValid,
                            }
                        }]

                    },
                    {
                        headerName: 'Number of Applications',
                        hide: !useNumberOfApplications,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useNumberOfApplications,
                                field: 'minNumberOfApplications',
                                type: 'select',
                                resizable: true, wrapText: true,
                                width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Max',
                                hide: !useNumberOfApplications,
                                field: 'maxNumberOfApplications',
                                type: 'select',
                                resizable: true, width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Increment',
                                hide: !useNumberOfApplications,
                                field: 'incrementNumberOfApplications',
                                type: 'select',
                                resizable: true, width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            }]
                    },
                    {
                        headerName: 'Application Interval (days)',
                        hide: !useIntervalBetweenApplications,
                        wrapText: true,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useIntervalBetweenApplications,
                                field: 'minApplicationInterval',
                                type: 'default',
                                resizable: true, width: 125,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputNumberRenderer,
                                editable: false,
                            },
                            {
                                headerName: 'Max',
                                hide: !useIntervalBetweenApplications,
                                field: 'maxApplicationInterval',
                                type: 'default',
                                resizable: true, width: 125,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputNumberRenderer,
                                editable: false,
                            },
                            {
                                headerName: 'Increment',
                                hide: !useIntervalBetweenApplications,
                                field: 'incrementApplicationInterval',
                                type: 'default',
                                resizable: true, width: 125,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputNumberRenderer,
                                editable: false,
                            }],
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAIGHA',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: 'Rate (lb/acre)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAILBACRE',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        hide: !useBBCH,
                        wrapHeaderText: true,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useBBCH,
                                wrapHeaderText: true,
                                field: 'minBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Max',
                                hide: !useBBCH,
                                field: 'maxBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        instance: this,
                                        columnThatModifies: 'maxBbchEarliest',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Increment',
                                hide: !useBBCH,
                                field: 'incrementBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            }
                        ]
                    },
                    {
                        headerName: 'Interception (%)',
                        wrapHeaderText: true,
                        children: [{
                            headerName: '',
                            field: 'cropInterception',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            width: 110,
                            resizable: true, editable: false,
                            cellRenderer: InputSearchRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                            }
                        }],
                    },
                    {
                        headerName: 'Date Type',
                        children: [{
                            headerName: '',
                            field: 'dateType',
                            type: 'select',
                            width: 150,
                            resizable: true, editable: false,
                            filter: 'agSetColumnfilter',
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    options: [{ value: Constants.DATE_TYPE_VALUES.RELATIVE }, { value: Constants.DATE_TYPE_VALUES.ABSOLUTE }],
                                    optionValue: 'value',
                                    optionLabel: 'value',
                                    columnThatModifies: 'dateType',
                                    disabled: !isProjectOwnershipValid,
                                };
                            }
                        }]

                    },
                    {
                        headerName: '',
                        children: [{
                            headerName: 'Crop Event',
                            field: 'cropEvent',
                            type: 'select',
                            resizable: true,
                            width: 150,
                            editable: false,
                            filter: 'agSetColumnfilter',
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    options: [{ value: 'Emergence' }, { value: 'Harvest' }, { value: 'Maturity' }],
                                    optionValue: 'value',
                                    optionLabel: 'value',
                                    columnThatModifies: 'cropEvent',
                                    disabled: !isProjectOwnershipValid,
                                };
                            },
                            cellStyle: (params: any) => {
                                if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                    return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                                }
                                return null;
                            }
                        }]

                    },
                    {
                        headerName: 'Application Date',
                        children: [{
                            headerName: '',
                            field: 'appWindow',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            width: 150,
                            resizable: true, editable: false,
                            hide: options.geographySelected !== Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                            cellRenderer: ViewButtonRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    disabled: !isProjectOwnershipValid,
                                };
                            },
                            cellStyle: (params: any) => {
                                if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                    return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                                }
                                return null;
                            }
                        }]

                    },
                    {
                        headerName: 'Application Date',
                        children: [
                            {
                                headerName: 'Min',
                                field: 'minApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                width: 150,
                                resizable: true, editable: false,
                                hide: false,
                                cellRenderer: CalendarRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        instance: this,
                                        columnThatModifies: 'minApplicationDate',
                                        highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                                        disabled: !isProjectOwnershipValid,
                                    };
                                },
                            },
                            {
                                headerName: 'Max',
                                field: 'maxApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                resizable: true, hide: false,
                                editable: false,
                                cellRenderer: CalendarRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        instance: this,
                                        columnThatModifies: 'maxApplicationDate',
                                        highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                                        disabled: !isProjectOwnershipValid,
                                    };
                                },
                            },
                            {
                                headerName: 'Increment',
                                field: 'incrementApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                resizable: true, hide: false,
                                editable: false,
                                cellRenderer: InputNumberRenderer,
                                cellRendererParams: {
                                    disabled: !isProjectOwnershipValid
                                }
                            },
                            {
                                headerName: 'Days Since',
                                children: [{
                                    headerName: '',
                                    editable: false,
                                    field: 'daysSince',
                                    cellRenderer: InputNumberRenderer,
                                    cellRendererParams: {
                                        disabled: !isProjectOwnershipValid,
                                    },
                                    width: 100,
                                    resizable: true, type: 'default',
                                    filter: 'agSetColumnFilter',
                                    hide: false,
                                    cellStyle: (params: any) => {
                                        if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                            return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                                        }
                                        return null;
                                    }
                                }]
                            },
                            {
                                headerName: 'Application Method',
                                children: [{
                                    headerName: '',
                                    field: 'applicationMethodPk',
                                    type: 'select',
                                    resizable: true,
                                    width: 130,
                                    filter: 'agSetColumnfilter',
                                    editable: false,
                                    cellRenderer: DropdownListRenderer,
                                    cellRendererParams: (params: any) => {
                                        return {
                                            instance: this,
                                            options: options.applicationMethods,
                                            optionValue: 'datafieldValuePk',
                                            optionLabel: 'value',
                                            columnThatModifies: 'applicationMethodPk',
                                            disabled: !isProjectOwnershipValid,
                                        };
                                    },
                                }]
                            },
                            {
                                headerName: 'Soil Depth Incorporated (cm)',
                                children: [{
                                    headerName: '',
                                    field: 'soilDepth',
                                    type: 'default',
                                    filter: 'agSetColumnFilter',
                                    resizable: true, width: 150,
                                    cellRenderer: InputNumberRenderer,
                                    cellRendererParams: {
                                        disabled: !isProjectOwnershipValid,
                                    },
                                    editable: false,
                                }]

                            },
                            {
                                headerName: 'Repeat Interval for Application Events',
                                children: [{
                                    headerName: '',
                                    field: 'repeatInterval',
                                    type: 'default',
                                    filter: 'agSetColumnFilter',
                                    resizable: true, width: 150,
                                    cellRenderer: InputNumberRenderer,
                                    cellRendererParams: {
                                        disabled: !isProjectOwnershipValid,
                                    },
                                    editable: isProjectOwnershipValid,
                                }]

                            }
                        ]
                    }
                ]
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }
        ]
    }

    public getInverseApplicationColumnsDefinition(parentRowNode?: RowNode, isProjectOwnershipValid?: boolean): any {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Location',
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        field: 'location',
                        editable: false,
                        type: 'default',
                        width: 150,
                        resizable: true,
                        hide: false,
                        filter: 'agSetColumnFilter',
                        validationType: 'string',
                        cellEditor: 'agTextCellEditor',
                        cellRenderer: (params: any) => {
                            const node = params.node;
                            if (parentRowNode) {
                                const isSelected = node.data && parentRowNode.data.applicationSchemeXApplicationWindow?.some((x: any) => node.data.applicationDateGroundWaterPk === x.applicationDateGroundWaterPk);
                                if (isSelected) {
                                    node.setSelected(true);
                                }
                            }
                            return node.data.location;
                        },
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                            };
                        }
                    }],
            },
            {
                headerName: 'Application Date',
                children: [
                    {
                        headerName: 'Min',
                        field: 'min',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 150,
                        resizable: true,
                        editable: false,
                        hide: false,
                        validationType: 'string',
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'min',
                                highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Max',
                        field: 'max',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 150,
                        resizable: true,
                        editable: false,
                        hide: false,
                        validationType: 'string',
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'max',
                                highlightDate: new Date(Constants.DEFAULT_GW_YEAR, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Increment',
                        field: 'increment',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        hide: false,
                        validationType: 'number',
                        cellEditor: NumericEditorRenderer,
                        editable: false,
                    }
                ]
            }
        ]
    }

    configureDetailGrid(isProjectOwnershipValid: boolean): ColGroupDef[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Number',
                        field: 'applicationNumber',
                        width: 110,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: false,
                        sort: "asc",
                    },
                    {
                        headerName: 'Application Interval',
                        field: 'applicationInterval',
                        width: 110,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: isProjectOwnershipValid,
                        cellStyle: (params: any) => {
                            if (params?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    }
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        editable: !isProjectOwnershipValid,
                    },
                ]
            },
        ]
    }

}

