<div class="grid" *ngIf="!isLoading" style="margin-bottom: -11px;">
    <p-accordion class="col-12">
        <p-accordionTab header="{{headerNonDietary}}">
            <ng-template pTemplate="content" *ngIf="!isUSA">
                <div class="col-12">
                    <app-hhra-run-risk-assessment-general-buttons [selectedProject]="selectedProject"
                    (reRun)="refreshData()" (downloadFiles)="onDownloadClick()" [isRefreshingIcon]="isRefreshingIcon"
                    [isAllFilesDownloading]="isAllFilesDownloading" [projectStatusStyle]="projectStatusStyle"></app-hhra-run-risk-assessment-general-buttons>
                    <app-hhra-run-risk-assessment-opex-result (status)="closeModal($event)"
                        [selectedProject]="selectedProject" [subTabMenuService]="menuService"
                        [isProjectOwnershipValid]="isProjectOwnershipValid"
                        [opexInputsCombinations]="opexInputsCombinations"></app-hhra-run-risk-assessment-opex-result>
                </div>
            </ng-template>
            <p-tabView *ngIf="isUSA">
                <p-tabPanel class="mb-2" header="{{occupationalHandlerExposure}}">
                    <app-hhra-run-risk-assessment-general-buttons [selectedProject]="selectedProject"
                    (reRun)="refreshData()" (downloadFiles)="onDownloadClick()" [isRefreshingIcon]="isRefreshingIcon"
                    [isAllFilesDownloading]="isAllFilesDownloading" [projectStatusStyle]="projectStatusStyle"></app-hhra-run-risk-assessment-general-buttons>
                    <app-hhra-run-risk-assessment-occupational-handler-exposure (status)="closeModal($event)"
                        [selectedProject]="selectedProject" [subTabMenuService]="menuService"
                        [isProjectOwnershipValid]="isProjectOwnershipValid"
                        [opexInputsCombinations]="opexInputsCombinations"></app-hhra-run-risk-assessment-occupational-handler-exposure>
                </p-tabPanel>
                <p-tabPanel class="mb-2" header="{{occupationalReEntryExposure}}">

                </p-tabPanel>
                <p-tabPanel class="mb-2" header="{{ResidentialExposure}}">
                    
                </p-tabPanel>
            </p-tabView>
        </p-accordionTab>
        <p-accordionTab header="{{headerDietary}}">
            <ng-template pTemplate="content">
                <div class="col-12">

                </div>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>