<app-grid #grid
  ng-if="!show"
  [rowData]="rowData"
  [columnDefs]="columnsDef"
  [columnSizeMode]="columnSizeMode"
  [isMasterDetail]="true"
  [pagination]="true"
  [rowHeight]="rowHeight"
  [useAddAction]="isProjectOwnershipValid"
  [useModels]="true"
  [rowData]="rowData"
  [useDeleteAction]="true"
  [compartment]="compartment"
  [selectedProject]="selectedProject"
  [useModels]="true"
  [detailAutoRowHeigth]="true"
  [pagination]="true"
  [detailColumnDefs]="detailsColumnDef"
  (deleteEvent)="deleteRow($event)"
  (AddDetailEvent)="onAddDetail($event)"
  (DeleteDetailEvent)="onDeleteDetails($event)"
  (selectionChangedEvent)="dropDownSelectionChanged($event)"
  (dropDownSelectionChangeEvent)="dropDownSelectionChanged($event)"
  (onModelOptionsChangedEvent)="onModelOptionsChanged($event)"
  (addEvent)="newRowAdded($event)"
  (onBlurInputText)="onBlurInputText($event)"
  (onClickButtonEvent)="onSelectScenariosClick($event)"
  (listBoxSelectionChangeEvent)="listBoxSelectionChanged($event)"
  (onCheckboxSelectedEvent)="onCheckboxSelectedChanged($event)"
></app-grid>

<eea-gap-surfacewater-pwc-scenarios
  *ngIf="showScenarios()"
  [row]="selectedRow"
  [isProjectOwner]="isProjectOwnershipValid"
  (onSelectScenarios)="onSelectScenarios($event)"
/>
