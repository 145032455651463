import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project } from 'src/app/shared/models/project';

@Component({
  selector: 'app-hhra-run-risk-assessment-general-buttons',
  templateUrl: './hhra-run-risk-assessment-general-buttons.component.html',
  styleUrls: ['./hhra-run-risk-assessment-general-buttons.component.css']
})

export class HhraRunRiskAssessmentGeneralButtonsComponent {

  @Input() selectedProject?: Project;
  @Input() isAllFilesDownloading: boolean = false;
  @Input() isRefreshingIcon: boolean = false;
  @Input() projectStatusStyle: any = {
    statusId: 0,
    statusName: "",
    badgeStyle: ""
  };
  @Output() reRun: EventEmitter<boolean>;
  @Output() downloadFiles: EventEmitter<boolean>;

  constructor() {
    this.downloadFiles = new EventEmitter();
    this.reRun = new EventEmitter();
  }

  onDownloadClick(): void {
    this.downloadFiles.emit();
  }

  refreshData() {
    this.reRun.emit();
  }
}